// Import variables first
@use './variables' as *;

// Override default variables
$primary-color: #d7de28;
$backgroundimage: '/img/frontend/wimbeldon-3.jpg';

// Now import other styles
@use './site/flag-icon.min.css' as *;
@use './site/os-lib.css' as *;
@use './site/style';
