@use '../variables' as *;

:root {
    --main-color-1xbet: #1A5685;
    --main-color-1xbet-sec: #1B90DF;
    --main-color-1xbit: #212121;
    --main-color-1xbit-sec: #e6770e;
    --main-color-1win: #1c273d;
    --main-color-1win-sec: #2997ed;
    --main-color-22bet: #054146;
    --main-color-22bet-sec: #166970;
    --main-color-bcgame: #1c1e22;
    --main-color-bcgame-sec: #00de00;
    --main-color-bet-at-home: #afafaf;
    --main-color-bet-at-home-sec: #7ec51e;
    --main-color-bet365: #14805e;
    --main-color-bet365-sec: #ffdf1b;
    --main-color-betano: #1c2026;
    --main-color-betano-sec: #ff6600;
    --main-color-betfair: #ffb80c;
    --main-color-betfair-sec: #1e1e1e;
    --main-color-betonline: #565656;
    --main-color-betonline-sec: #ed3536;
    --main-color-betway: #000000;
    --main-color-betway-sec: #00a826;
    --main-color-betwinner: #14805e;
    --main-color-betwinner-sec: #ffdf1b;
    --main-color-bodog: #1f1f1f;
    --main-color-bodog-sec: #c42126;
    --main-color-bovada: #364250;
    --main-color-bovada-sec: #cb2026;
    --main-color-bwin: #000000;
    --main-color-bwin-sec: #ffcd04;
    --main-color-efbet: #171717;
    --main-color-efbet-sec: #ffb609;
	--main-color-ggbet: #1c1c1c;
	--main-color-ggbet-sec: #ff6633;
    --main-color-intertops: #333333;
    --main-color-intertops-sec: #e72d2f;
    --main-color-lsbet: #48952b;
    --main-color-lsbet-sec: #e47a7a;
    --main-color-marathonbet: #013d79;
    --main-color-marathonbet-sec: #c2ddf3;
    --main-color-melbet: #363c3f;
    --main-color-melbet-sec: #f6ce60;
    --main-color-nairabet: #013be5;
    --main-color-nairabet-sec: #f58634;
    --main-color-noxwin: #16130d;
    --main-color-noxwin-sec: #fab616;
    --main-color-palmsbet: #02314d;
    --main-color-palmsbet-sec: #cba03f;
    --main-color-reloadbet: #fece38;
    --main-color-reloadbet-sec: #8c8c8c;
    --main-color-tipbet: #dadada;
    --main-color-tipbet-sec: #fc0000;
    --main-color-tonybet: #4d4d4d;
    --main-color-tonybet-sec: #9000ff;
    --main-color-william-hill: #00143c;
    --main-color-william-hill-sec: #fafe05;
    --main-color-yesplay: #fe7a01;
    --main-color-yesplay-sec: #f3d22d;

    --rgba-1xbet: rgba(26,86,133, .96);
    --rgba-1xbit: rgba(33,33,33, .96);
    --rgba-1win: rgba(28,39,61, .96);
    --rgba-22bet: rgba(5,65,70, .96);
    --rgba-bcgame: rgba(28,30,34, .96);
    --rgba-bet-at-home: rgba(175,175,175, .96);
    --rgba-bet365: rgba(20,128,94, .96);
    --rgba-betano: rgba(28,32,38, .96);
    --rgba-betfair: rgba(255,184,12, .96);
    --rgba-betonline: rgba(86,86,86, .96);
    --rgba-betway: rgba(0,0,0, .96);
    --rgba-betwinner: rgba(20,128,94, .96);
    --rgba-bodog: rgba(31,31,31, .96);
    --rgba-bovada: rgba(54,66,80, .96);
    --rgba-bwin: rgba(0,0,0, .96);
    --rgba-efbet: rgba(23,23,23, .96);
    --rgba-ggbet: rgba(28, 28, 28, .96);
    --rgba-intertops: rgba(51,51,51, .96);
    --rgba-lsbet: rgba(72,149,43, .96);
    --rgba-marathonbet: rgba(1,61,121, .96);
    --rgba-melbet: rgba(54,60,63, .96);
    --rgba-nairabet: rgba(1,59,229, .96);
    --rgba-noxwin: rgba(22,19,13, .96);
    --rgba-palmsbet: rgba(2,49,77, .96);
    --rgba-reloadbet: rgba(254,206,56, .96);
    --rgba-tipbet: rgba(218,218,218, .96);
    --rgba-tonybet: rgba(77,77,77, .96);
    --rgba-william-hill: rgba(0,20,60, .96);
    --rgba-yesplay: rgba(254,122,1, .96);

    --cal-main: #fff;
    --navbar: #f8f9fb;
    --footer-back: #fff;
    --font-color: #788DA2;
    --font-color2: #5A6E81;
    --heading-col: #374757;
    --bg-color: #F8F9FB;
    --table-odd: #fff;
    --bg-image: url('/img/frontend/bodybg_transparent.png');
    --pred-image: url('/img/frontend/detail-bg.png');
    --bulb-image: url('/img/frontend/light-bulb.png');
    --calendar-image: url('/img/frontend/calendar-dark.png');
}

[data-theme="dark"] {
    --cal-main: #383c43;
    --navbar: #27282c;
    --footer-back: #383c43;
    --font-color: #fff;
    --font-color2: #ffffff;
    --heading-col: #ffffff;
    --bg-color: #27282c;
    --table-odd: #2e3237;
    --bg-image: url(/img/frontend/bm_bg.png);
    --pred-image: url(/img/frontend/detail-bg-dark.png);
    --bulb-image: url('/img/frontend/dark-bulb.png');
    --calendar-image: url('/img/frontend/calendar-light.png');
}

html {scroll-behavior: smooth;}

body {margin: 0;padding: 0;font-family: 'Ubuntu', sans-serif;background: var(--bg-image) no-repeat center bottom;background-color: var(--bg-color);background-position: center bottom; }

body {}

p { margin: 0;}

* {outline: none; }
a:hover {text-decoration: none;}
a:fouce {text-decoration: none; outline: none;}
a:visited, a:focus {outline: none; text-decoration: none;}
.slick-list {outline: none;}

/* gapping class  Start*/
::-webkit-scrollbar {width:10px;height:10px;}
::-webkit-scrollbar-button:start:decrement, ::-webkit-scrollbar-button:end:increment {display:none;}
::-webkit-scrollbar-track-piece {background-color:#fff;-webkit-border-radius:0px;border-left:1px solid #fff;}
::-webkit-scrollbar-thumb:vertical {-webkit-border-radius:0px;background:$primary-color;}

::-webkit-scrollbar-track-piece {
    background-color: var(--cal-main);
    -webkit-border-radius: 0px;
    border-left: 1px solid var(--cal-main);
}

.clear {clear: both;}

.content-section p a {
    color: $primary-color;
}
.content-section p a:hover {
    color: $primary-color;
    text-decoration:underline;
}

/* padding */
.pad-r {padding-right: 0 !important}
.pad-l {padding-left: 0 !important}
.pd-60-tb {padding-top: 60px !important; padding-bottom: 60px !important;}
.pd-68-tb {padding-top: 68px !important; padding-bottom: 68px !important;}
.pd-49-tb {padding-top: 49px !important; padding-bottom: 49px !important;}
.pd-60-t {padding-bottom: 80px !important;}
.pd-60-b {padding-bottom: 60px !important;}
.pd-15-l {padding-left: 15px !important;}
.no-pad {padding: 0 !important}
/* margin */
.mr-30-tb {margin-top: 30px; margin-bottom: 30px;}
.mr-60-tb {margin-top: 60px !important; margin-bottom: 60px !important;}
.main-mr-l-r {margin-left: 15px; margin-right: 15px}

h1.inner {
    margin: 0;
    padding: 0;
    color: var(--heading-col);
}
/* gapping class  End*/

.border-no-r {border-right: 0 !important;}
.border-no-l {border-left: 0;}

/* Sweep To Top */
.hvr-sweep-to-top { vertical-align: middle;-webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0); box-shadow: 0 0 1px rgba(0, 0, 0, 0);position: relative; -webkit-transition-property: color;
    transition-property: color; -webkit-transition-duration: 0.3s;transition-duration: 0.3s;}

.hvr-sweep-to-top:before { content: "";position: absolute; z-index: -1; top: 0;left: 0; right: 0; bottom: 0; background: #044c83;
    -webkit-transform: scaleY(0); transform: scaleY(0);-webkit-transform-origin: 50% 100%; transform-origin: 50% 100%;  -webkit-transition-property: transform;
    transition-property: transform;  -webkit-transition-duration: 0.3s; transition-duration: 0.3s; -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;}

.hvr-sweep-to-top:hover, .hvr-sweep-to-top:focus, .hvr-sweep-to-top:active {color: #333;}
.hvr-sweep-to-top:hover:before, .hvr-sweep-to-top:focus:before, .hvr-sweep-to-top:active:before {-webkit-transform: scaleY(1);  transform: scaleY(1);}

.clear {clear: both;}

.main-header  {background: var(--navbar);margin: 0;padding: 23px 0 30px 0;position: fixed;top: 0;width: 100%;z-index: 100;box-shadow: 0px 2px 4px #456EA41A;}
.logo {margin: 0; padding: 0;}
.social-main {background: transparent;/* width: 379px; */padding: 0 10px 0 0;margin: 0 0 14px 0;display: inline-block;height: 39px;}
.chat-icon {margin: 10px 0 16px 0;padding: 0;display: inline-block;}
.chat-icon a:hover { color: #000;}
.chat-icon ul {margin: 0;padding: 0;list-style: none;/* display: inline-block; */}
.chat-icon ul li {margin: 0 0 0 10px;padding: 0;display: inline-block;font-size: 16px;color: #8c8c8c;border-left: 1px solid #c0c0c0;/* font-weight: 700; */padding: 0 0 0 15px; text-transform: capitalize;}
.chat-icon ul li:first-child {border: none;}
.chat-icon ul li:last-child {padding-right: 0;/* border: none; */}
.chat-icon ul li a {padding: 0 0;color: #c0c0c0; transition: all 0.4s ease-in-out;}
.chat-icon ul li a:hover {text-decoration: none;}
.chat-icon ul li a i {margin: 0 10px 0 0;}


.cal-main {
    margin: 110px 0 0 0;
    padding: 15px 15px 0px 15px;
    background: var(--cal-main) 0% 0% no-repeat padding-box;
    box-shadow:0 0px 5px 2px #456EA41A;
    /* height: 100px; */
    padding: 25px 15px 0;
}
.fix {
    margin: 20px 0 0 0 !important;
}

.cal i img {
    width: 38px;
}

.cal i {
    display: inline-block;
    vertical-align: top;
}

.cal h1, .cal h3 {
    display: inline-block;
    color: var(--font-color);
    text-transform: uppercase;
    font-size: 13px;
    margin: 0;
    padding: 0;
}

.cal h1 span, .cal h3 span {
    display: block;
    font-size: 22px;
    font-weight: 700;
    color: $primary-color;
}
.bookmakers-row h2 {
    color: var(--heading-col);
    font-weight: bold;
    font-size: 22px;
}
body {
    font-family: 'Ubuntu', sans-serif;
}
.nav>li>a:focus, .nav>li>a:hover {
    background-color: transparent;
}
.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
    background-color: transparent;
    color: $primary-color;
}
.navbar-nav {
    padding-top: 12px;
    padding-bottom: 6px;
}
.navbar-brand {
    padding: 0px; /* firefox bug fix */
    height: 65px;
}
.navbar-brand>img {
    height: 100%;
    padding: 15px; /* firefox bug fix */
    width: auto;
}
.navbar-custom {
    background-color: var(--navbar);
    box-shadow: 0px 2px 4px #456EA41A;
}
.navbar-custom .navbar-nav>li>a, .dropdown-menu>li>a {
    color: var(--font-color);
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    transition: all 0.5s ease-in-out;
    font-family: 'Ubuntu', sans-serif;
}
.navbar-custom .navbar-nav>li.lang>a {
    font-weight: bold;
}
.navbar-custom .navbar-nav>li.active>a, .navbar-custom .navbar-nav>li>a:hover {
    color: $primary-color;
}
.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
    color: $primary-color;
    text-decoration: none;
    background-color: transparent;
}
.navbar-custom .navbar-nav>li.active>a::after {
    content: "";
    width: 27px;
    border-bottom: 2px solid $primary-color;
    position: relative;
    display: block;
    margin: 0 auto;
    margin-top: 4px;
}
.navbar-custom .navbar-nav>li>a:hover::after {
    content: "";
    width: 27px;
    border-bottom: 2px solid $primary-color;
    position: relative;
    display: block;
    margin: 0 auto;
    margin-top: 4px;
}
.navbar-custom .navbar-nav>li>a.login:hover::after, .navbar-nav>li>a.bulb-switch:hover::after {
    display: none;
}
.navbar-custom .navbar-nav>li.dropdown>a:hover::after {
    display: none;
}
.dropdown-menu {
    border-top: 2px solid $primary-color;
    background-color: var(--navbar);
}
.navbar-form .form-control {
    border: 1px solid #CAD2E2;
    border-radius: 60px;
    font-size: 16px;
    color: var(--font-color);
    padding: 4px 42px 3px 13px;
    width: 200px;
}
.navbar-custom input::placeholder {
    font-style: italic;
}
.navbar-custom input:hover, .navbar-custom input:focus{
    border-color: $primary-color;
    box-shadow: none;
}
.fa-user {
    color: $primary-color;
    margin-right: 3px;
}
span.flag-icon {
    margin-right: 5px;
}
.nav>li>a.bulb-switch>img {
    width: 20px;
}
.nav>li>a.bulb-switch>svg {
    width: 20px;
}
.nav>li>a.bulb-switch .bulb {
    width: 26px;
    height: 31px;
    background-image: var(--bulb-image);
    background-size: cover;
    display: block;
    margin-top: -5px;
}
.cal .calendar, .cal-mob .calendar {
    width: 37px;
    height: 39px;
    background-image: var(--calendar-image);
    background-size: cover;
    display: block;
    float: left;
    margin-right: 7px;
}
/* The side navigation menu */
.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1040; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: var(--cal-main); /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}
.sidenav ul {
    padding-left: 20px;
}
.sidenav>ul>li {
    float: none;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dad7d8;
}
/* The navigation menu links */
.sidenav a {
    text-decoration: none;
    font-size: 18px;
    color: #93a4b5;
    display: block;
    transition: 0.3s;
    text-transform: uppercase;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
    color: #93a4b5;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.mobile-nav-icon {
    float: left;
    margin-top: 8px;
    display: none;
    margin-right: 10px;
    opacity: .5;
}
.srch1 {
    float: left;
    margin-top: 25px;
    margin-right: 10px;
    display: none;
}
.srch1>i {
    font-size: 18px;
    color: #93a4b5;
}
.srch {display: inline-block;position: relative;}
.srch input {border: 1px solid #CAD2E2;border-radius: 60px;font-size: 16px;color: var(--font-color);  margin: 20px auto;}
.srch input::placeholder {color: var(--font-color); font-style: italic;}
.srch {
    background-color: #fff;
    width: 100%;
    padding: 15px 60px;
    border-top: 2px solid $primary-color;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
    transition: margin-left .5s;
    padding: 20px;
}
.cal-nav {margin: 0; padding: 0;}
.cal-nav ul {margin: 0;padding: 0;list-style: none;text-align: right;}
.cal-nav ul li {margin: 0 0 0 20px;padding: 0;display: inline-block;text-align: center;}
.cal-nav ul li a {color:var(--font-color);text-decoration: none;}
.cal-nav ul li:nth-child(1) a {color: #ccd4dd;}
.cal-nav ul li:nth-child(2) a {color: #ccd4dd;}
.cal-nav ul li:nth-child(3) a {color: #ccd4dd;}
.cal-nav ul li a p {font-size: 16px;text-transform: capitalize;/* font-weight: 300; */}
.cal-nav ul li a p span {font-size: 20px;font-weight: 700;display: block;}
.cal-nav ul li:hover,.cal-nav ul li.active a {color: $primary-color;}
.cal-nav ul li.active {border-bottom: 2px solid $primary-color;padding: 0 0 10px 0;}

.maindetail-bx {background-image: var(--pred-image);background-repeat: no-repeat;background-position: top center;padding: 15px 10px 0;background-color: var(--cal-main);box-shadow: 0px 2px 4px #456EA41A;border-radius: 4px;opacity: 1;}
.detailbx {background: var(--cal-main);height: auto;border: 2px solid #FF314D;box-shadow: 0px 4px 8px #30486726;padding: 7px 10px 6px;border-radius: 4px;margin: 0 0 17px 0;}
.detailbx h4 {color: var(--font-color);font-size: 14px;margin: 0 0 10px 0;padding: 0;}
.detailbx h4 span {float: right;color: #FF314D;font-weight: 600;text-transform: uppercase;}
.d-li {margin: 7px 0 0 0;padding: 0;display: inline-block;width: 70%;vertical-align: top;}
.d-li ul {margin: 0; padding: 0; list-style: none;}
.d-li ul li {margin: 0 0 7px 0;padding: 0;color: var(--font-color);display: block;}
.d-li ul li.active p {color: var(--heading-col);}
.d-li ul li p {color: var(--font-color);font-size: 15px;display: inline-block;font-weight: 500;width: 88%;padding: 0 0 0 5px;}
.d-li ul li p span {float: right; font-weight: 700;}
.timebx {border-left: 2px solid #d7dee9;margin: 7px 0 0 7px;padding: 0 0 0 10px;display: inline-block;}
.timebx p {display: inline-block;color: #FF314D;font-size: 14px;font-weight: 600;margin: 0 0 8px 0;}
.timebx p i img {width: 20px;}
.timebx>a.btn {background: #ff314d;display: block;color: #fff;border: none;border-radius: 3px;padding: 2px 12px; font-weight: bold; width: 93px;}
.timebx a i img {width: 21px;}
.d-li ul li i { margin: 0 0px 0 0;}

.timebx>a.btn-red {
    background: #ff314d;
}
.timebx>a.btn-green {
    background: #33A45C;
}
.timebx>a.btn-dark {
    background: #535b57;
}
.detailbx.no-live {border: 2px solid transparent;}
.detailbx.no-live .timebx button {background: $primary-color;}
.detailbx.no-live .timebx p {color: var(--font-color);}
.detailbx.no-live h4 span {display: none;}
.add1 {margin: 0; padding: 0;}
.add1 img {width: 100%;}
button.btn1 img {width: 19px;}
button.btn1:hover {background: transparent;}
/* Css */
.add2 {margin: 108px 0 0 0; position: relative; overflow: hidden;}
.add2 iframe {border: none;}



/* .resp-iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 0;} */
.add3 {margin: 15px 0 0 0;}
.headin1 {margin: 39px 0 15px 0;padding: 0;font-size: 16px;color: var(--heading-col);font-weight: 700;text-transform: uppercase;}
.headin1 span {color: $primary-color;}
.headin2 {margin: 25px 0 14px 0;padding: 0;font-size: 16px;color: var(--heading-col);font-weight: 700;text-transform: uppercase;}
.headin2 span {color: $primary-color;}
.postblg i img {display: inline-block; width: 100%; height: auto;}
.postblg {margin: 0 0 10px 0; padding: 3px 0 2px 2px; background: var(--cal-main); box-shadow: 0px 2px 4px #456EA41A;}
.postblg i {
    width: 76px;
    /* overflow: hidden; */
    display: inline-block;
    height: 76px;
    overflow: hidden;
    border-radius: 5px;
    vertical-align: top;
}

.postinr {
    display: inline-block;
    width: 181px;
    padding: 4px 0 0 7px;
    background: var(--cal-main);
}

.postinr p {
    font-size: 14px;
    color: var(--heading-col);
}

.postinr a {
    color: $primary-color;
    font-size: 14px;
    font-weight: 500;
}

.newsblg i img {display: inline-block; width: 100%; height: auto;}
.newsblg {margin: 0 0 10px 0; padding: 3px 0 2px 2px; background: #fff; box-shadow: 0px 2px 4px #456EA41A;}
.newsblg i {
    width: 106px;
    /* overflow: hidden; */
    display: inline-block;
    height: 109px;
    overflow: hidden;
    border-radius: 5px;
    vertical-align: top;
}

.bloginr {
    display: inline-block;
    width: 84%;
    padding: 4px 0 0 17px;
    background: #fff;
}

.bloginr p {
    font-size: 14px;
    color: var(--heading-col);
}

.bloginr a {
    color: $primary-color;
    font-size: 14px;
    font-weight: 500;
}


.btn2 {border: 2px solid $primary-color;text-transform: capitalize;font-size: 15px;color: $primary-color;border-radius: 5px;padding: 3px 7px;margin: 0 0 17px 0;display: inline-block;}
.btn2:hover {background: $primary-color; color: #fff; text-decoration: none;}
.more-pre h2 {
    font-size: 26px;
    font-weight: 700;
    text-transform: capitalize;
    color: var(--heading-col);
    display: inline-block;
    margin: 15px 20px 30px 0;
}

.more-pre h2 span {
    color: $primary-color;
}

.predict-bx {
    padding: 25px 0;
}

.predict-bx h4 {color: #9FAFBF; font-size: 16px; text-transform: capitalize; display: inline-block;}
.predict-bx i img {width: 27px; margin: 0 4px 0 0;vertical-align: middle;}

.predict-bx h4 span {color: var(--font-color)}
.predict-bx h4 span i {font-size: 13px; font-style: normal;}

.btn3 {float: right;font-size: 14px;color: #9FAFBF;font-weight: 700;margin: 10px 15px 0 0;}
.btn3:hover {text-decoration: none;}
.btn3 i {margin: 0 10px 0 0;}

.drk-lst {margin: 0 0 0 0;padding: 8px 6px 6px;background: var(--navbar);}
.drk-lst ul {margin: 0; padding: 0; list-style: none;}
.drk-lst ul li  {margin: 0;padding: 0;display: inline-block;color: #b8bbbf;font-size: 15px;font-weight: 700;}
.drk-lst ul li p {display: inline-block;}
.drk-lst ul li i {background: #4758A9;width: 26px;height: 26px;border-radius: 80px;vertical-align: middle;display: inline-block;margin: 0 11px 0 6px;vertical-align: middle;}
.drk-lst ul li:nth-child(1) {width: 7%;}

.drk-lst ul li:nth-child(2) {width: 82%;text-align: center;}
.drk-lst ul li:nth-child(3) a {background: #33A45C;color:#fff;display:block;padding: 4px 12px;border-radius:4px;font-size:11px;font-weight:300;text-align:center;min-width:87px;}
.drk-lst ul li:nth-child(3) a:hover {text-decoration: none;}
.drk-lst ul li span {color: var(--font-color);}
.drk-lst ul li:nth-child(2) a {
    color: #b8bbbf;
}
/* .main-txt {background: url(/img/frontend/im3.png) no-repeat; background-size: contain; position: center right;} */
.no-btn {
    cursor: default;
}
.main-txt h2 {
    font-size: 26px;
    font-weight: 700;
    text-transform: capitalize;
    color: var(--heading-col);
    display: inline-block;
    margin: 0 20px 30px 0;
}

.main-txt h2 span {
    color: $primary-color;
}

.main-txt {
    margin: 60px 0 0 0;
}

.main-txt p {
    color: var(--heading-col);
    font-size: 14px;
    width: 412px;
    margin: 0 0 20px 0;
}
.main-txt img {
    margin: 59px 0 0 0;
}
.lsitab {
    margin: 0;
    padding: 0;
}

.lsitab p {
    background: var(--cal-main);
    color: var(--font-color);
    padding: 15px;
}

.lsitab ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.lsitab ul li {
    margin: 0 0;
    padding: 0;
}

.lsitab ul li a {
    background: #fff;
    padding: 0 0;
    color: var(--font-color);
    font-size: 14px;
    display: block;
    padding: 8px 0 6px 13px;
    border-top: 1px solid #d0c9c9;
    font-weight: 700;
}
.maintabs.index .lsitab>ul>li:first-child a{
    border: 0;
}
.maintabs.index .lsitab>ul li a {
    background: var(--cal-main);
    padding: 0 0;
    color: var(--font-color);
    font-size: 14px;
    display: block;
    padding: 8px 0 6px 13px;
    border-top: 1px solid #d0c9c9;
    font-weight: 700;
}
.maintabs.index .nav-tabs>li {
    margin: 0 15px;
    padding: 0;
    border-bottom: 0;
}
.lsitab ul li a:hover {
    text-decoration: none;
}

.lsitab ul li a i {
    float: right;
    margin: 2px 12px 0 0;
}

.lsitab ul li a:hover {color: $primary-color;}

.maintabs .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    background: none;
}


.bg-styl {
    /* height: 208px; */
    background: var(--cal-main) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #456EA41A;
    padding: 15px 0;
}

.country-league {
    background: #fff;
    border-radius: 4px;
}
.country-league-head {
    cursor: pointer;
    border-radius: 4px;
    background: var(--cal-main);
}
.predict-bx a.collapsed:before {
    float: right !important;
    content: "\f067";
    font-family: FontAwesome;
    color: #95a6b6
}
a.btn3.collapsed i {display: none;}

.main-footer {margin: 60px 0 0 0; padding:30px 0 30px 0; background: var(--footer-back);}
.ft-list-2 {margin: 0;text-align: left;}
.ft-list-2 h6 {margin: 0;font-size: 16px;color: $primary-color;margin: 7px 0 8px 0;padding: 0;font-weight: 700;text-transform: uppercase;}
.ft-list-2 h5 {margin: 0;font-size: 16px;color: #fff;margin: 30px 0 0 0;padding: 0;font-weight: 700;text-transform: uppercase;}
.ft-list-2 ul {margin: 20px 0 0 0;list-style: none;padding: 0;font-size: 0;text-align: left;}
.ft-list-2 ul li {margin: 0 10px 8px 0;padding: 0;font-size: 12px;text-align: left;text-transform: capitalize;display: inline-block;}
.ft-list-2 ul li a {color: var(--font-color);transition: all ease-out;transition-duration: 0.4s;}
.ft-list-2 ul li a:hover {text-decoration: none;color: #000;}
.ft-list-2 ul li a i {color: var(--font-color);margin: 0 3px 0 0;padding: 0;font-size: 19px;}
.ft-list-2.pd-les ul li {margin-left: 0}
.ft-logo {margin: 7px 0 0 0;}
.ft-logo img {width: 173px;}
.ft-logo img:last-child {margin-top: 2px;}
.ft-logo p {color: var(--font-color); margin: 25px 0 20px 0;}

.footer-nav {
    text-align: left;
}
.footer-nav h6 {
    font-size: 16px;
    color: $primary-color;
    margin: 7px 0 8px 0;
    padding: 0;
    font-weight: 700;
    text-transform: uppercase;
}
.footer-nav ul {
    padding-left: 0;
    margin-top: 20px;
}
.footer-nav ul li {
    list-style: none;
    text-align: left;
    margin-top: 5px;
}
.footer-nav ul li a {
    color: var(--font-color);
    transition: all ease-out;
    transition-duration: 0.4s;
    font-size: 14px;
    text-transform: capitalize;
}

.drk-lst ul li i.clr-darkblu {background: #4758a9 !important}
.drk-lst ul li i.clr-green {background: $primary-color !important}
.drk-lst ul li i.clr-light-blu {background: #46c0ff !important}
.drk-lst ul li i.clr-light-norange {background: #f9ac47 !important}
.drk-lst ul li i.clr-darkgreen {background: $primary-color !important;}
.drk-lst ul li i.clr-darkgrey {background: #535b57 !important;}

.ft-list-2 ul li:first-child a i {color: #3b5998;}
.ft-list-2 ul li:last-child a i {color: #00acee;}


.mob-lst-s ul li i.clr-green {background: $primary-color;}
.mob-lst-s ul li i.clr-green {background: $primary-color !important}
.mob-lst-s ul li i.clr-light-blu {background: #46c0ff !important}
.mob-lst-s ul li i.clr-light-norange {background: #f9ac47 !important}
.mob-lst-s ul li i.clr-darkgreen {background: $primary-color !important;}
.mob-lst-s ul li i.clr-darkgrey {background: #535b57 !important;}


.drk-lst.light-clr {background: var(--cal-main);}
.country-league i {margin: 0 0 0 8px; color: #95a6b6}

.drk-lst ul li:nth-child(3) a.redclr {background: #ff314d}
.drk-lst ul li:nth-child(3) a.darkclr {background: #535b57}
.drk-lst ul li:nth-child(3) a.grenclr {background: #33a45c}

.maintabs {background: var(--cal-main);padding: 0 0 5px 0;border-radius: 4px;}
.maintabs .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {border: none;}
.maintabs ul.nav.nav-tabs {border: none;}
.maintabs .nav>li>a {padding: 10px 0;border: none;}
.maintabs .nav-tabs>li>a:hover {background: transparent;box-shadow: none;border: none;/* padding: 0; */}
.maintabs .nav>li>a img {width: 20px;}
.maintabs .nav-tabs>li {margin: 0 15px;padding: 0;border-bottom: 2px solid transparent;}
.maintabs .nav-tabs>li.active {border-bottom: 2px solid $primary-color;/* padding-bottom: 7px; */}

.nav-tabs>li.fst .act-im {display: none;}
.nav-tabs>li.active.fst .act-im {display: block;}
.nav-tabs>li.fst.active .no-act-im {display: none;}

.nav-tabs>li.scnd .act-im {display: none;}
.nav-tabs>li.active.scnd .act-im {display: block;}
.nav-tabs>li.scnd.active .no-act-im {display: none;}

.nav-tabs>li.thrd .act-im {display: none;}
.nav-tabs>li.active.thrd .act-im {display: block;}
.nav-tabs>li.thrd.active .no-act-im {display: none;}

.nav-tabs>li.frth .act-im {display: none;}
.nav-tabs>li.active.frth .act-im {display: block;}
.nav-tabs>li.frth.active .no-act-im {display: none;}

.nav-tabs>li.fth .act-im {display: none;}
.nav-tabs>li.active.fth .act-im {display: block;}
.nav-tabs>li.fth.active .no-act-im {display: none;}

.btn4 {font-size: 12px; color: $primary-color;}
.btn4:hover {text-decoration: none;}
.btn5 {margin: 0;padding: 7px;display: block;background: $primary-color;font-size: 15px;color: #fff;width: 135px;margin: 6px 0 0 0;border-radius: 4px;border: none;}
.btn5:hver {text-decoration: none;}
.srch button.btn5 {
    display: inline-block !important;
    margin: 0 5px;
    padding: 4px 15px;
    width: auto;
}

.main-privacy {margin: 0; padding: 0; background: #4a5867; padding: 20px 0; position: fixed; bottom: 0; width: 100%;}
.main-privacy .txt p {color: #fff; font-size: 14px;}
.main-privacy .txt p span {display: block; color: $primary-color;}
.main-privacy .txt p span a { color: #fff; text-decoration: underline;}
.btn-cookies a {color: #fff;background: $primary-color;width: 187px;display: inline-block;text-align: center;padding: 10px 0;border-radius: 4px;margin: 0 10px 0 0;}
.top-btn {padding: 20px;color: #fff;font-size: 25px;position: fixed;right: 30px;bottom: 212px; z-index: 300; display: none;}
.top-btn i {color: #fff; background: $primary-color; padding: 15px; border-radius: 10%;}

.add2 img {width: 100%;height: auto;}
.slideshow span { left: -19px !important;}
.add3 img {width: 100%;height: auto;}
.add1 span {
    display: inline-block;
    width: 19%;
    text-align: center;
    font-weight: 700;
    color: #788daa;
    /* border: 1px solid #bbb; */
}

.add1 i {
    display: inline-block;
    width: 80%;
}

.points-dot {width: 158px; display: inline-block; vertical-align:  middle;}
span.frst-tem {width: 250px; text-align: right; display: inline-block; overflow: hidden; vertical-align: middle;}
span.sec-tem {width: 230px;text-align: left;display: inline-block;color: #b8bbbf !important;vertical-align: middle;}

.cal-mob {display: none;}
.d-show {display: none;}

/* Responsive
------------------------------------*/
/* Non Responsive Nav */

@media screen and (min-width: 1900px){

}

@media screen and (min-width: 1600px){

}

@media screen and (min-width: 992px) and (max-width:1169px){
    .timebx {margin: 5px 0 0 0;}
    .d-li ul li p {width: 87%;}
    .postinr p { font-size: 11px; color: var(--heading-col);}
    .postblg i {width: 62px; height: 65px;}
    .newsblg i {width: 62px; height: 65px;}
    .main-nav .nav ul>li {font-size: 15px;margin: 0 10px 0 0;}
    .postinr {width: 143px;}
    .main-nav .nav ul>li:nth-child(2) {background-position: 76px 8px;}
    .timebx a {}
    .btn-cookies a {width: 168px;}
    .rit-nav a {margin: 0;}
    .rit-nav a.btn.btn-primary.dropdown-toggle {margin: 0;}
    .lsitab ul li a {font-size: 11px;}
    .maintabs .nav-tabs>li {margin: 0 10px;}


    span.sec-tem {width: 182px;}
    .drk-lst ul li:nth-child(2) {width: 79%; }
    span.frst-tem {width: 230px; }

}

/*Mobile*/
@media screen and (min-width: 768px) and (max-width:991px){
    .main-nav .nav ul li ul li {font-size: 12px;}
    .srch input {width: 109px; font-size: 12px;}
    .main-nav {margin: 0}
    .main-nav .nav ul>li {font-size: 12px; margin: 0 4px 0 0;}
    .rit-nav a {margin: 0;}
    .rit-nav a.btn.btn-primary.dropdown-toggle {margin: 0;}
    .cal h1 {font-size: 11px;}
    .cal-nav ul li {margin-left: 10px;}
    .postblg {background: transparent;}
    .postblg i{width: 54px;height: 91px;}
    .postinr {width: 157px;}
    .newsblg {background: transparent;}
    .bloginr i{width: 54px;height: 91px;}
    .bloginr {width: 157px;}

}


@media screen and (min-width: 320px) and (max-width: 767px){


    /*.main-mob-nav .srch.active { display: block; }*/

    .cal-nav ul li a p span {
        font-size: 13px;
    }

    .cal-nav ul li {margin: 0 12px 0 0;}
    .cal-nav ul li:last-child {margin: 0}
    .add2 {display: none;}


    .cal {display: none;}
    /****************/
    .cal-mob {display: block;border-top: 1px solid #e2dede;margin: 10px 0 10px 0;padding: 8px 0 0 0;}
    .cal-mob i img {width: 40px;}
    .cal-mob i {display: inline-block; vertical-align: top;}
    .cal-mob p {display: inline-block; color: var(--font-color); text-transform: uppercase;  font-size: 13px;}
    .cal-mob span {display: inline-block; font-size: 37px; font-weight: 700;color: $primary-color;  float: right; text-transform: uppercase; margin: -4px 0 0 0;}
    .detailbx h4 {font-size: 12px;}
    .detailbx h4 {font-size: 12px;}
    .timebx a {font-size: 12px;}
    .timebx p { font-size: 12px;}
    .d-li ul li i img {width: 15px;}
    .timebx p i img {width: 13px;}
    .detailbx  {padding: 7px 6px 6px;}
    .d-li ul li p { font-size: 12px; width: 80%;}
    .timebx {margin: 6px 0 0 0;}
    .add1 {display: block;}
    .cal-nav ul {text-align: left;}
    .postinr {width: 72%;}
    .btn2 {padding: 3px 0 3px 6px;}
    .btn2 i {margin: 0 10px 0 14px;}
    .more-pre h5 {margin: 15px 0 11px 0;}
    .d-hide {display: none; text-align: left;}
    .predict-bx h4 {font-size: 12px;}
    .d-show {display: block;}
    .mob-lst-s {margin: 0; padding: 0 10px;}
    .mob-lst-s ul {margin: 0; padding: 0; list-style: none; margin-bottom: 40px; border-bottom: 2px solid #ecececad;}
    .mob-lst-s:last-child ul{border: 0;}
    .mob-lst-s ul li {margin: 0; padding: 0; display: block;}
    .mob-lst-s ul li { font-size: 15px; color: #93a4b5; font-weight: 400; margin: 0 0 15px 0}
    .mob-lst-s ul li a {float: none; color: #fff;background: $primary-color; width: 85px; text-align: center;padding: 2px 6px;margin: -3px 10px 0 0; border-radius: 4px; display: inline-block;}
    .mob-lst-s ul li a:first-child{
        background-color: transparent; color: var(--font-color);
    }
    .mob-lst-s ul li a:last-child{
        float: right;
        color: #fff;
    }
    .mob-lst-s ul li i {
        background: #4758A9;
        width: 26px;
        height: 26px;
        border-radius: 80px;
        vertical-align: middle;
        display: inline-block;
        margin: 0 11px 0 0px;
        vertical-align: middle;
    }
    .mob-lst-s ul li p {display: inline-block; color: #788daa; font-weight: 700; width: 82%;}
    .mob-lst-s ul li p span {float: right;}
    .mob-lst-s ul li:last-child p {color: #b8bbc4;}
    .country-league {margin: 0 0 0px 0;

        border: 1px solid #b9b3b3;
        padding: 8px 0 8px;}
    .grey-btn {background: #535b57 !important;}
    .red-btn {background: #ff314d !important;}
    .main-txt p {width: 100%;}

    .add3 {display: block;}
    .add3 img {width: 100%; height: auto;}
    .ft-logo { margin: 00px 0 16px 0; text-align: center;}
    .btn-cookies a { display: block; width: 100%; margin: 14px 0 5px 0;}
    .top-btn {right: 0px;}
    .cal-main {margin: 44px 0 0 0;}


    .dark.predict-bx h4 { color: #000;}
    .dark.predict-bx h4 span {color: #000;}
    .dark.predict-bx h4 span i {color: #000;}
    .country-league.dark {background: #ecececad;}

    .timebx a i img {display: inline-block; margin: 0;}
    .headin1, .headin2 {text-align: left;margin: 20px 0;}
    .more-pre h2 {text-align: left; width: 100%;}


    .btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open>.dropdown-toggle.btn-primary.focus, .open>.dropdown-toggle.btn-primary:focus, .open>.dropdown-toggle.btn-primary:hover {background: transparent;     color: #93a4b5;
        -webkit-appearance: none;    font-weight: 700;}

    /*ul.dropdown-menu {font-size: 12px;width: 43px; min-width: 43px;}     */
    ul.dropdown-menu li {font-size: 12px;display: block;text-align: left;padding: 0;}
    .dropdown-menu>li>a {padding: 3px 31px 4px 10px;text-align: left;/* background: red; */margin: 0 0 7px -3px;color: #788daa;}




    .srch i.fa.fa-close {position: absolute;top: -58px;left: 37px;background: $primary-color;padding: 22px 10px;color: #fff;width: 31px;height: 56px;}

    .book_country {
        float:left;position:relative;
    }
    .book_flag {
        float:left;position:absolute !important;padding-top:5px;left:27px;
    }

}
/*Bookmakers page starts*/

.main-content {
    margin: 108px 0px 0px 0;
}
.bookmakers-menu {
    padding-right: 15px;
}
.bookmakers-menu i:before {
    color: var(--font-color2);
    font-size: 22px;
    margin-left: 5px;
}
.bookmakers-menu .col-md-2{
    padding-right: 0;
}

.bookmakers-menu a.menu-item{
    background-color: var(--cal-main);
    color: var(--font-color2);
    font-size: 13px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: normal;
    box-shadow: 0px 2px 4px #456EA41A;
    width: 100%;
    height: 56px;
    border: 0;
    text-align: center;
    padding: 9px;
    border-radius: 4px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    position: relative;
}
.bookmakers-menu a.menu-item.active{
    background-color: $primary-color;
    color: #FFFFFF;
    font-weight: bold;
}
.bookmakers-menu a.menu-item.disabled{
    background-color: transparent;
    color: #a9b3be;
    box-shadow: inset 0 0 0 2px #E3E6EE;
}
.bookmakers-menu a.menu-item.disabled i:before {
    color: #a9b3be;
}
.bookmakers-menu a.menu-item.disabled:hover{
    box-shadow: inset 0 0 0 2px #E3E6EE;
}
.bookmakers-menu a.menu-item.active i:before {
    color: #FFFFFF;
}
.menu-icon {
    width: 20%;
    text-align: center;
}
.menu-text{
    width: 80%;
    text-align: center;
}

.bookmakers-menu a.menu-item:hover{
    box-shadow: inset 0 0 0 2px $primary-color;
}
.content-section {
    background-color: var(--cal-main);
    box-shadow: 0px 2px 4px #456EA41A;
    padding: 20px 40px;
    margin-top: 10px;
    border-radius: 5px;
}
.content-section p {
    color: var(--font-color2);
    margin-top: 20px;
    font-size: 16px;
    text-align: justify;
    text-justify: inter-word;
}
.content-section.tennis p {
    padding: 0px 10px 5px 10px;
}
.content-section p a {
    white-space: pre-line;
    word-wrap: break-word;
}
.content-section ul li, .content-section ol li {
    color: var(--font-color2);
    margin-top: 5px;
    font-size: 16px;
}
.content-section ul li a, .content-section ol li a {
    color: $primary-color;
}
.content-section ul li a:hover, .content-section ol li a:hover {
    text-decoration:underline;
}
.content-section ul, .content-section ol {
    margin: 0;
}
.content-section h4 {
    color: var(--heading-col);
    font-weight: bold;
    font-size: 18px;
}
.content-section h3 {
    color: var(--heading-col);
    font-weight: bold;
    font-size: 18px;
}
.content-section h2 {
    color: var(--heading-col);
    font-weight: bold;
    font-size: 24px;
    margin-top: 0;
}
.articles h2, .articles h4, .articles iframe {
    margin-top: 20px;
    margin-bottom: 10px;
}
.content-section h1 {
    color: var(--heading-col);
    font-weight: bold;
    font-size: 24px;
    margin-top: 0;
}
.refine-search h3{
    color: var(--font-color);
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
}
.refine-search .form-group label {
    font-size: 13px;
    color: var(--font-color2);
    position: relative;
    top: 14px;
    left: 10px;
    background: #fff;
    padding: 0 3px;
    text-align: left;
}
.refine-search .form-control{
    border: 2px solid #CAD2E2;
    border-radius: 4px;
    height: 48px;
    font-style: italic;
    color: var(--font-color2);
}
.refine-search .checkbox span{
    font-size: 13px;
    color: var(--font-color2);
    font-weight: bold;
    text-align: left;
    padding-top: 6px;
    display: block;
}
.refine-search .checkbox label span{
    margin-top: 3px;
}
.refine-search .checkbox {
    margin-left: 20px;
}
.refine-search .checkbox label{
    margin-top: 11px;
}
.refine-search .col-md-4, .refine-search .col-md-3{
    padding-left: 7px;
    padding-right: 7px;
}
.refine-search input.submit {
    background-color: $primary-color;
    border: 0;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: #FFFFFF;
    padding: 15px 55px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none !important;
    float: right;
    height: auto;
    box-shadow: 0px 2px 4px #456EA41A;
    margin-top: 22px;
}
.checkbox {
    width: 100%;
    position: relative;
    display: block;
}
.checkbox label {
    position: relative;
    min-height: 34px;
    display: block;
    padding-left: 40px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
}
.checkbox label span {
    position: absolute;
    top: 32%;
    font-size: 14px;
    font-weight: normal !important;
    transform: translateY(-50%);
}
.checkbox label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 22px;
    height: 22px;
    transition: transform 0.28s ease;
    border-radius: 3px;
    border: 2px solid #CAD2E2;

}
.checkbox label:after {
    content: "";
    display: block;
    width: 12px;
    height: 7px;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: rotate(-65deg) scale(0);
    position: absolute;
    top: 10px;
    left: 9px;
}
.checkbox input[type="checkbox"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;
}
.checkbox input[type="checkbox"]:checked ~ label:before {
    background-color: $primary-color;
    border: 2px solid $primary-color;
}
.checkbox input[type="checkbox"]:checked ~ label:after {
    transform: rotate(-45deg) scale(1);
}
.checkbox input[type="checkbox"]:focus + label::before {
    outline: 0;
}
.rating-box {
    position:relative;
    vertical-align: middle;
    font-size: 1.3em;
    font-family: FontAwesome;
    display:inline-block;
    color: $primary-color;
    display: inline-block;
    position: relative;
}
.rating-box:before{
    content: "\f006  \f006  \f006  \f006  \f006";
}
.rating-box .rating {
    position: absolute;
    left:0;
    top:0;
    white-space:nowrap;
    overflow:hidden;
    color: $primary-color;
}
.rating-box .rating:before {
    content: "\f005  \f005  \f005  \f005  \f005";
}
.bookmakers-list-item {
    background-color: var(--cal-main);
    margin-top: 10px;
    display: block;
    min-height: 75px;
    box-shadow: 0px 2px 4px #456EA41A;
    border-radius: 4px;
}
.bookmakers-list-item.sponsor::before {
    content:"Sponsored";
    display: inline-block;
    position: absolute;
    margin-left: -143px;
    margin-top: 5px;
    color: $primary-color;
    font-size: 13px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}
.bookmakers-list-item.sponsor {
    background-color: #F2FFF7;
    box-shadow: inset 0 0 0 2px $primary-color;
}
.bookmakers-list-item.sponsor img {
    -webkit-transform:scale(1, 0.95);
    -ms-transform:scale(1, 0.95);
    transform:scale(1, 0.95);
}
.bookmakers-list-item.sponsor .btn-green-outline {
    background-color: $primary-color;
    color: #fff;
}
.bookmakers-list-item img {
    display: inline-block;
    float: left;
}
.bookmakers-list-item .title {
    display: inline-block;
    margin-left: 15px;
}
.bookmakers-list-item .des {
    display: inline-block;
    margin-left: 15px;
}
.bookmakers-list-item p.h2{
    font-size: 20px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    color: $primary-color;
    text-decoration: underline;
    margin: 8px 0;
}
.bookmakers-list-item p.h5{
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    color: var(--heading-col);
    margin: 8px 0;
}
.bookmakers-list-item p.h5 img{
    margin-right: 10px;
}
.bookmakers-list-item p{
    font-size: 15px;
    color: var(--font-color2);
    margin-left: 32px;
}
span.green-highlight {
    color: $primary-color;
    font-weight: bold;
}
.bookmakers-list-item span.rating-digit{
    font-size: 17px;
    font-weight: bold;
    color: var(--font-color2);
    padding: 5px;
}
.bookmakers-list-item .cta {
    display: inline-block;
    float: right;
    margin-right: 20px;
    margin-top: 15px;
}
.btn-green-outline {
    border: 2px solid $primary-color;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;
    color: var(--heading-col);
    border-radius: 5px;
    padding: 8px 25px;
    display: inline-block;
}
.btn-green-outline:hover {
    background-color: $primary-color;
    color: #fff;
}
#DataTables_Table_0_filter label {
    color: var(--font-color2);
}
table.dataTable thead .sorting:after {
    content: ' \2195';
}
table.dataTable thead .sorting_asc:after {
    content: ' \2193';
}
table.dataTable thead .sorting_desc:after {
    content: ' \2191';
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    *cursor: hand;
    background-repeat: no-repeat;
    background-position: center right
}
.pagination, .dataTables_paginate .paging_simple_numbers {
    box-shadow: 0px 2px 4px #456EA41A;
    margin: 40px auto;
}
.pagination>li>a, .pagination>li>span, .paginate_button {
    position: relative;
    float: left;
    padding: 12px 10px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: var(--font-color) !important;
    text-decoration: none !important;
    background-color: var(--cal-main);
    font-size: 15px !important;
    border: 0;
    font-weight: normal !important;
}
.pagination>li>a.active, .current {
    border-bottom: 2px solid $primary-color;
    color: $primary-color !important;
    font-weight: bold !important;
}
.pagination>li>a:visited {
    background: var(--cal-main);
}
.pagination>li>a:first-child:hover{
    background: var(--cal-main);
    color: var(--font-color);
}
.pagination li i {
    font-weight: bold;
    margin: 0 5px;
}
.bookmakers-countries ul {
    background-color: var(--cal-main);
    box-shadow: 0px 2px 4px #456EA41A;
    border-radius: 4px;
    padding: 0;
}
.bookmakers-countries ul li {
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
    border-top: 2px solid #EAEEF3;
}
.bookmakers-countries ul li:first-child{
    border: 0;
}
.bookmakers-countries ul li a img {
    margin-right: 10px;
}

.bookmakers-countries ul li img{
    margin-top: -5px;
}

.bookmakers-countries ul li a {
    color: var(--font-color2);
    font-size: 14px;
    display: block;
    padding: 8px 0 6px 13px;
    font-weight: 700;
}
.bookmakers-countries ul li:hover a {
    color: $primary-color;
}
.bookmakers-countries ul li a i{
    float: right;
    margin-right: 15px;
    font-weight: lighter;
}
.add2 {
    overflow: auto !important;
}

.back_1xbet {
    background-color: var(--main-color-1xbet) !important;
}
.back_1xbit {
    background-color: var(--main-color-1xbit) !important;
}
.back_1win {
    background-color: var(--main-color-1win) !important;
}
.back_22bet {
    background-color: var(--main-color-22bet) !important;
}
.back_bcgame {
    background-color: var(--main-color-bcgame) !important;
}
.back_bet-at-home {
    background-color: var(--main-color-bet-at-home) !important;
}
.back_bet365 {
    background-color: var(--main-color-bet365) !important;
}
.back_betano {
    background-color: var(--main-color-betano) !important;
}
.back_betfair {
    background-color: var(--main-color-betfair) !important;
}
.back_betonline {
    background-color: var(--main-color-betonline) !important;
}
.back_betway {
    background-color: var(--main-color-betway) !important;
}
.back_betwinner {
    background-color: var(--main-color-betwinner) !important;
}
.back_bodog {
    background-color: var(--main-color-bodog) !important;
}
.back_bovada {
    background-color: var(--main-color-bovada) !important;
}
.back_bwin {
    background-color: var(--main-color-bwin) !important;
}
.back_efbet {
    background-color: var(--main-color-efbet) !important;
}
.back_ggbet {
    background-color: var(--main-color-ggbet) !important;
}
.back_intertops {
    background-color: var(--main-color-intertops) !important;
}
.back_lsbet {
    background-color: var(--main-color-lsbet) !important;
}
.back_marathonbet {
    background-color: var(--main-color-marathonbet) !important;
}
.back_melbet {
    background-color: var(--main-color-melbet) !important;
}
.back_nairabet {
    background-color: var(--main-color-nairabet) !important;
}
.back_noxwin {
    background-color: var(--main-color-noxwin) !important;
}
.back_palmsbet {
    background-color: var(--main-color-palmsbet) !important;
}
.back_reloadbet {
    background-color: var(--main-color-reloadbet) !important;
}
.back_tipbet {
    background-color: var(--main-color-tipbet) !important;
}
.back_tonybet {
    background-color: var(--main-color-tonybet) !important;
}
.back_william-hill {
    background-color: var(--main-color-william-hill) !important;
}
.back_yesplay {
    background-color: var(--main-color-yesplay) !important;
}

.sec_1xbet {
    background-color: var(--main-color-1xbet-sec) !important;
    color: #fff !important;
}
.sec_1xbit {
    background-color: var(--main-color-1xbit-sec) !important;
    color: #fff !important;
}
.sec_1win {
    background-color: var(--main-color-1win-sec) !important;
    color: #fff !important;
}
.sec_22bet {
    background-color: var(--main-color-22bet-sec) !important;
    color: #fff !important;
}
.sec_bcgame {
    background-color: var(--main-color-bcgame-sec) !important;
    color: #fff !important;
}
.sec_bet-at-home {
    background-color: var(--main-color-bet-at-home-sec) !important;
    color: #fff !important;
}
.sec_bet365 {
    background-color: var(--main-color-bet365-sec) !important;
    color: var(--main-color-bet365) !important;
}
.sec_betano {
    background-color: var(--main-color-betano-sec) !important;
    color: #fff !important;
}
.sec_betfair {
    background-color: var(--main-color-betfair-sec) !important;
    color: #fff !important;
}
.sec_betonline {
    background-color: var(--main-color-betonline-sec) !important;
    color: #fff !important;
}
.sec_betway {
    background-color: var(--main-color-betway-sec) !important;
    color: #fff !important;
}
.sec_betwinner {
    background-color: var(--main-color-betwinner-sec) !important;
    color: var(--main-color-betwinner) !important;
}
.sec_bodog {
    background-color: var(--main-color-bodog-sec) !important;
    color: #fff !important;
}
.sec_bovada {
    background-color: var(--main-color-bovada-sec) !important;
    color: #fff !important;
}
.sec_bwin {
    background-color: var(--main-color-bwin-sec) !important;
    color: #000 !important;
}
.sec_efbet {
    background-color: var(--main-color-efbet-sec) !important;
    color: var(--main-color-efbet) !important;
}
.sec_ggbet {
    background-color: var(--main-color-ggbet-sec) !important;
    color: var(--main-color-ggbet) !important;
}
.sec_intertops {
    background-color: var(--main-color-intertops-sec) !important;
    color: #fff !important;
}
.sec_lsbet {
    background-color: var(--main-color-lsbet-sec) !important;
    color: #fff !important;
}
.sec_marathonbet {
    background-color: var(--main-color-marathonbet-sec) !important;
    color: var(--main-color-marathonbet) !important;
}
.sec_melbet {
    background-color: var(--main-color-melbet-sec) !important;
    color: #fff !important;
}
.sec_nairabet {
    background-color: var(--main-color-nairabet-sec) !important;
    color: #fff !important;
}
.sec_noxwin {
    background-color: var(--main-color-noxwin-sec) !important;
    color: var(--main-color-noxwin) !important;
}
.sec_palmsbet {
    background-color: var(--main-color-palmsbet-sec) !important;
    color: #fff !important;
}
.sec_reloadbet {
    background-color: var(--main-color-reloadbet-sec) !important;
    color: #fff !important;
}
.sec_tipbet {
    background-color: var(--main-color-tipbet-sec) !important;
    color: #fff !important;
}
.sec_tonybet {
    background-color: var(--main-color-tonybet-sec) !important;
    color: #fff !important;
}
.sec_william-hill {
    background-color: var(--main-color-william-hill-sec) !important;
    color: var(--main-color-william-hill) !important;
}
.sec_yesplay {
    background-color: var(--main-color-yesplay-sec) !important;
    color: var(--main-color-yesplay) !important;
}

.rgba-1xbet {
    box-shadow: inset 0 0 0 1000px var(--rgba-1xbet) !important;
}
.rgba-1xbit {
    box-shadow: inset 0 0 0 1000px var(--rgba-1xbit) !important;
}
.rgba-1win {
    box-shadow: inset 0 0 0 1000px var(--rgba-1win) !important;
}
.rgba-22bet {
    box-shadow: inset 0 0 0 1000px var(--rgba-22bet) !important;
}
.rgba-bcgame {
    box-shadow: inset 0 0 0 1000px var(--rgba-bcgame) !important;
}
.rgba-bet-at-home {
    box-shadow: inset 0 0 0 1000px var(--rgba-bet-at-home) !important;
}
.rgba-bet365 {
    box-shadow: inset 0 0 0 1000px var(--rgba-bet365) !important;
}
.rgba-betano {
    box-shadow: inset 0 0 0 1000px var(--rgba-betano) !important;
}
.rgba-betfair {
    box-shadow: inset 0 0 0 1000px var(--rgba-betfair) !important;
}
.rgba-betonline {
    box-shadow: inset 0 0 0 1000px var(--rgba-betonline) !important;
}
.rgba-betway {
    box-shadow: inset 0 0 0 1000px var(--rgba-betway) !important;
}
.rgba-betwinner {
    box-shadow: inset 0 0 0 1000px var(--rgba-betwinner) !important;
}
.rgba-bodog {
    box-shadow: inset 0 0 0 1000px var(--rgba-bodog) !important;
}
.rgba-bovada {
    box-shadow: inset 0 0 0 1000px var(--rgba-bovada) !important;
}
.rgba-bwin {
    box-shadow: inset 0 0 0 1000px var(--rgba-bwin) !important;
}
.rgba-efbet {
    box-shadow: inset 0 0 0 1000px var(--rgba-efbet) !important;
}
.rgba-ggbet {
    box-shadow: inset 0 0 0 1000px var(--rgba-ggbet) !important;
}
.rgba-intertops {
    box-shadow: inset 0 0 0 1000px var(--rgba-intertops) !important;
}
.rgba-lsbet {
    box-shadow: inset 0 0 0 1000px var(--rgba-lsbet) !important;
}
.rgba-marathonbet {
    box-shadow: inset 0 0 0 1000px var(--rgba-marathonbet) !important;
}
.rgba-melbet {
    box-shadow: inset 0 0 0 1000px var(--rgba-melbet) !important;
}
.rgba-nairabet {
    box-shadow: inset 0 0 0 1000px var(--rgba-nairabet) !important;
}
.rgba-noxwin {
    box-shadow: inset 0 0 0 1000px var(--rgba-noxwin) !important;
}
.rgba-palmsbet {
    box-shadow: inset 0 0 0 1000px var(--rgba-palmsbet) !important;
}
.rgba-reloadbet {
    box-shadow: inset 0 0 0 1000px var(--rgba-reloadbet) !important;
}
.rgba-tipbet {
    box-shadow: inset 0 0 0 1000px var(--rgba-tipbet) !important;
}
.rgba-tonybet {
    box-shadow: inset 0 0 0 1000px var(--rgba-tonybet) !important;
}
.rgba-william-hill {
    box-shadow: inset 0 0 0 1000px var(--rgba-william-hill) !important;
}
.rgba-yesplay {
    box-shadow: inset 0 0 0 1000px var(--rgba-yesplay) !important;
}

.border_1xbet {
    background-color: var(--main-color-1xbet);
    border: 2px solid var(--main-color-1xbet-sec);
}
.border_1xbit {
    background-color: var(--main-color-1xbit);
    border: 2px solid var(--main-color-1xbit-sec);
}
.border_1win {
    background-color: var(--main-color-1win);
    border: 2px solid var(--main-color-1win-sec);
}
.border_22bet {
    background-color: var(--main-color-22bet);
    border: 2px solid var(--main-color-22bet-sec);
}
.border_bcgame {
    background-color: var(--main-color-bcgame);
    border: 2px solid var(--main-color-bcgame-sec);
}
.border_bet-at-home {
    background-color: var(--main-color-bet-at-home);
    border: 2px solid var(--main-color-bet-at-home-sec);
}
.border_bet365 {
    background-color: var(--main-color-bet365);
    border: 2px solid var(--main-color-bet365-sec);
}
.border_betano {
    background-color: var(--main-color-betano);
    border: 2px solid var(--main-color-betano-sec);
}
.border_betfair {
    background-color: var(--main-color-betfair);
    border: 2px solid var(--main-color-betfair-sec);
}
.border_betonline {
    background-color: var(--main-color-betonline);
    border: 2px solid var(--main-color-betonline-sec);
}
.border_betway {
    background-color: var(--main-color-betway);
    border: 2px solid var(--main-color-betway-sec);
}
.border_betwinner {
    background-color: var(--main-color-betwinner);
    border: 2px solid var(--main-color-betwinner-sec);
}
.border_bodog {
    background-color: var(--main-color-bodog);
    border: 2px solid var(--main-color-bodog-sec);
}
.border_bovada {
    background-color: var(--main-color-bovada);
    border: 2px solid var(--main-color-bovada-sec);
}
.border_bwin {
    background-color: var(--main-color-bwin);
    border: 2px solid var(--main-color-bwin-sec);
}
.border_efbet {
    background-color: var(--main-color-efbet);
    border: 2px solid var(--main-color-efbet-sec);
}
.border_ggbet {
    background-color: var(--main-color-ggbet);
    border: 2px solid var(--main-color-ggbet-sec);
}
.border_intertops {
    background-color: var(--main-color-intertops);
    border: 2px solid var(--main-color-intertops-sec);
}
.border_lsbet {
    background-color: var(--main-color-lsbet);
    border: 2px solid var(--main-color-lsbet-sec);
}
.border_marathonbet {
    background-color: var(--main-color-marathonbet);
    border: 2px solid var(--main-color-marathonbet-sec);
}
.border_melbet {
    background-color: var(--main-color-melbet);
    border: 2px solid var(--main-color-melbet-sec);
}
.border_nairabet {
    background-color: var(--main-color-nairabet);
    border: 2px solid var(--main-color-nairabet-sec);
}
.border_noxwin {
    background-color: var(--main-color-noxwin);
    border: 2px solid var(--main-color-noxwin-sec);
}
.border_palmsbet {
    background-color: var(--main-color-palmsbet);
    border: 2px solid var(--main-color-palmsbet-sec);
}
.border_reloadbet {
    background-color: var(--main-color-reloadbet);
    border: 2px solid var(--main-color-reloadbet-sec);
}
.border_tipbet {
    background-color: var(--main-color-tipbet);
    border: 2px solid var(--main-color-tipbet-sec);
}
.border_tonybet {
    background-color: var(--main-color-tonybet);
    border: 2px solid var(--main-color-tonybet-sec);
}
.border_william-hill {
    background-color: var(--main-color-william-hill);
    border: 2px solid var(--main-color-william-hill-sec);
}
.border_yesplay {
    background-color: var(--main-color-yesplay);
    border: 2px solid var(--main-color-yesplay-sec);
}

/*Bookmakers page end*/
/*Prediction page starts*/
.prediction_head {
    background-image: url($background-image);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 240px;
    box-shadow: inset 0 0 0 1000px rgba(55, 71, 87, .8);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}
.prediction-head-container{
    box-shadow: 0px 2px 4px #456EA41A;
    border-radius: 5px;
}
.prediction_head .league{
    padding-top: 25px;
}
.prediction_head .league p{
    color: #fff;
    font-size: 18px;
}
.prediction_head .league p img{
    margin-right: 10px;
    margin-left: 26px;
}
.prediction_head .title h1{
    font-weight: bold;
    font-size: 28px;
    color: #fff;
}
.prediction_head .timing p{
    color: #fff;
    font-size: 18px;
    margin-top: 8px;
    display: inline-block;
}
.prediction_head .timing p i{
    font-size: 28px;
    font-weight: normal;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -5px;
}
.prediction_head_footer {
    min-height: 55px;
    width: 100%;
    box-shadow: inset 0 0 0 1000px rgba(51, 164, 92, .5);
    margin-top: auto;
}
.prediction_head_footer .row {
    padding: 0;
}
.prediction_head_footer p{
    line-height:55px;
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}
.prediction_head_footer img {
    border-radius: 5px;
    margin-left: 10px;
    height: 27px;
}
.prediction_head_footer .col-md-4:nth-child(1) {
    padding-left: 40px;
}

.prediction_head_footer .col-md-4:nth-child(3) {
    padding-right: 40px;
}
.main-content .content-text h3{
    color: var(--heading-col);
    font-size: 24px;
    font-weight: bold;
    margin-top: 25px;
}
.main-content .content-text p{
    color: var(--font-color2);
    margin-top: 20px;
    font-size: 16px;
}
.main-content .content-text ul li {
    color: var(--font-color2);
    margin-top: 5px;
    font-size: 16px;
}
.content-section table tr td, .content-section table tr th {
    padding: 10px 20px !important;
}
.predictions {
    margin-top: 10px;
}
.predictions h3, .more-info h3, .odds-comparison h2, .odds-comparison h3 {
    font-size: 26px;
    margin-bottom: 0px;
    color: var(--font-color2);
}
.predictions h5{
    font-size: 20px;
    font-weight: 500;
    color: var(--font-color2);
}
.predictions h5.votes{
    margin-top: 69px;
}
.circle {
    position: relative;
    margin-top: 10px;
    cursor: pointer;
    width: 105px;
    height: 105px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}
.circle span{
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 26px;
    font-weight: 500;
    color: var(--font-color2);
    font-family: 'Ubuntu', sans-serif;
}
.circle-container h6 {
    font-size: 18px;
    color: var(--heading-col);
    font-family: 'Ubuntu', sans-serif;
    text-align: center;
}
.odds-comparison .content-section {
    padding: 0;
}
.odds-comparison h3 {
    font-size: 26px;
    margin-bottom: 0px;
    margin-top: 30px;
}
.content-section .maintabs {
    padding: 0;
}
.content-section .maintabs .nav>li>a {
    padding: 10px 0;
    border: none;
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    color: var(--font-color2);
    text-decoration: none;
}
.content-section .maintabs .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: $primary-color;
}
.maintabs ul.nav.nav-tabs {
    border-bottom: 2px solid var(--bg-color);
    padding-left: 20px;
}
td.green a {
    text-decoration: underline;
}
td.green, td.green a {
    font-size: 15px;
    color: $primary-color;
    font-weight: 600;
}
.content-section table tr td, .content-section table tr th{
    padding: 10px 30px;
}
.content-section table th:first-child i{
    vertical-align: middle;
    margin-top: 5px;
}
.content-section table td{
    color: var(--heading-col);
    font-size: 15px;
    font-weight: 600;
    font-family: 'Ubuntu', sans-serif;
}
.content-section table th{
    color: var(--font-color2);
    font-size: 15px;
    font-weight: 600;
    font-family: 'Ubuntu', sans-serif;
}
.content-section table th u{
    color: var(--heading-col);
}
.content-section table tfoot th:first-child{
    font-size: 18px;
}
.content-section .table-striped>tbody>tr:nth-of-type(odd) {
    background-color: var(--navbar);
}
.content-section .table>tbody>tr>td {
    line-height: 1.42857143;
    vertical-align: bottom;
    border-top: 0;
}
.content-section .table i {
    vertical-align: top;
    margin-left: 5px;
}
.content-section .table>thead tr th{
    border: 0;
}
.content-section .table>tbody tr:first-child{
    border-top: 2px solid #EAEEF3;
}
.table span.green-bg {
    background-color: $primary-color;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: -10px;
}
.content-section .table>tfoot tr th{
    border-top: 2px solid #EAEEF3;
}
.content-section .table>tbody>tr>td img {
    margin-right: 10px;
}
.content-section .table tr td.green-highlight {
    color: $primary-color;
}
.content-section .table tr td.red-highlight {
    color: #DC223B;
}
.content-section .table tr td i {
    font-size: 12px;
    margin-top: 5px;
}
.more-info .content-section {
    padding: 0;
}
.more-info .odds-comparison h3 {
    font-size: 26px;
    margin-bottom: 0px;
    margin-top: 30px;
}
.more-info table tr td {
    color: var(--heading-col);
    font-weight: normal;
    font-size: 15px;
}
.more-info table tr.green-border{
    border: 2px solid $primary-color !important;
}
.more-info table tr.green-border td{
    font-weight: bold;
}
.more-info #Video {
    padding: 0 20px 20px 20px;
}
.more-info #Video img{
    margin-top: 20px;
}
.more-info #Stats {
    padding: 40px;
}
.more-info #Stats h5{
    color: var(--heading-col);
    font-size: 18px;
}
.more-info #Stats h3{
    color: var(--font-color2);
    font-size: 26px;
}
.more-info #Stats .green-bg {
    background-color: #33A45C;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-top: 5px;
    display: inline-block;
}
.more-info #Stats .red-bg {
    background-color: #DC223B;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-top: 5px;
    display: inline-block;
}
.more-info #Stats .gray-bg {
    background-color: #c3c3c3;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-top: 5px;
    display: inline-block;
}
.more-info .last-matches {
    margin-top: 60px;
}
.more-info .last-matches .match{
    margin-top: -15px;
    margin-bottom: 30px;
    border: 2px solid #CAD2E2;
    border-radius: 5px;
    min-height: 70px;
}
.more-info .last-matches .match .row div {
    padding: 15px 0 0 0;
    float: none;
    display: inline-block;
}
.last-matches .match h3{
    font-size: 20px;
    color: var(--font-color2);
    font-weight: normal;
}
.last-matches .match span {
    margin-right: 20px;
}
.last-matches .match span.team {
    color: var(--font-color2);
}
.last-matches h6{
    font-size: 15px;
    color: var(--heading-col);
    font-weight: normal;
    position: relative;
    top: 8px;
    background-color: var(--bg-color);
    padding: 5px 20px;
    font-weight: 500;
    display: inline-block;
}
.table-responsive {
    min-height: .01%;
    overflow-x: unset;
}
.sidebar {
    padding-top: 90px;
}
.bookmakers-row .bookmakers-sidebar {
    padding-top: 40px;
}
.offer-sm {
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    margin-top: 3px;
    margin-bottom: 3px;
}
.offer-sm h6{
    font-size: 15px;
    font-weight: bold;
    padding: 0;
    color: #fff;
    margin-top: 0;
}
.offer-sm p{
    font-size: 10px !important;
    font-weight: normal;
    color: #fff !important;
}
.offer-sm a{
    font-size: 14px !important;
    font-weight: bold !important;
    padding: 3px 25px;
    background: #1B90DF;
    border-radius: 5px;
    margin-top: 17px;
    text-decoration: none !important;
}
.offer-sm img{
    display: inline-block;
    margin-top: 20px;
    opacity: .9;
}
.offer-sm {
    background-image: url('/img/frontend/offer_bg_1xbet.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}
.red-highlight {
    color: #DC223B !important;
}
.live-streams ul {
    background-color: #fff;
    box-shadow: 0px 2px 4px #456EA41A;
    border-radius: 4px;
    padding: 0;
}
.live-streams ul li {
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
    border-top: 2px solid #EAEEF3;
}
.live-streams ul li:first-child{
    border: 0;
}
.live-streams ul li a img {
    margin-right: 10px;
    float: left;
}
.live-streams ul li a {
    color: #030303;
    font-size: 15px;
    display: block;
    padding: 6px 15px 6px 15px;
    font-weight: 700;
    text-decoration: underline;
    text-align: right;
}
.live-streams ul li:hover a {
    color: $primary-color;
}
.related-prediction ul {
    background-color: var(--cal-main);
    box-shadow: 0px 2px 4px #456EA41A;
    border-radius: 4px;
    padding: 0;
}
.related-prediction ul li {
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
    border-top: 2px solid #EAEEF3;
}
.related-prediction ul li:first-child{
    border: 0;
}
.related-prediction ul li a img {
    margin-right: 10px;
}
.related-prediction ul li {
    color: var(--font-color2);
    font-size: 14px;
    display: block;
    padding: 15px 20px;
    font-weight: 700;
}
.related-prediction ul li img{
    margin-top: -5px;
}
.related-prediction ul li small{
    color: var(--font-color2);
    font-weight: lighter;
    font-size: 14px;
}
.related-prediction ul li a {
    color: var(--font-color2);
    font-size: 14px;
    display: block;
    font-weight: 700;
}
.related-prediction ul li:hover a {
    color: $primary-color;
}
.related-prediction ul li a i{
    float: right;
    font-weight: lighter;
}
.sidebar .add3 {
    margin: 3px 0 0 0;
}
.sidebar .headin2 {
    margin: 50px 0 14px 0;
}
.sidebar .headin2:first-child {
    margin: 25px 0 14px 0;
}
.bookmaker-profile-container {
    background-image: url("/img/frontend/bookmaker_profile_bg.png");
    background-size: contain;
    background-position: bottom;
    width: 100%;
    min-height: 440px;
    margin-top: 80px;
    z-index: 90;
    position: relative;
}
.bookmaker-profile {
    background-image: url("/img/frontend/bm_bg.png");
    background-size: cover;
    background-position: bottom left;
    width: 100%;
    min-height: 440px;
    margin-top: 80px;
}
.bookmaker-profile .screenshots img{
    margin-top: 80px;
}
.bookmaker-profile h2{
    margin-top: 8px;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 24px;
    color: #fff;
}
.bookmaker-profile ul {
    padding-left: 0;
    margin-bottom: 20px;
}
.bookmaker-profile h1{
    margin-top: 8px;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 24px;
    color: #fff;
}
.bookmaker-profile .logo-container {
    border: 2px solid var(--font-color);
    border-radius: 5px;
    padding: 0;
    margin-top: 10px;
}
.bookmaker-profile .logo-container h6{
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    background-color:#DC223B;
    width: 100%;
    text-align: center;
    padding: 5px 5px;
    margin: 0;
}
.bookmaker-profile .logo-container img{
    width: 150px;
    height: 30px;
    display: block;
    margin: 46px auto;
}
.bookmaker-profile .register {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    margin-top: 10px;
    display: block;
    width: 100%;
    text-align: center;
    padding: 12px;
    border-radius: 5px;
}
.bookmaker-profile form {
    margin-top: 10px;
}
.bookmaker-profile form select{
    border: 2px solid #1B90DF;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    background-color: transparent;
    width: 48%;
    display: inline-block;
    padding: 8px;
}

.bookmaker-profile form input{
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    background-color: transparent;
    width: 48%;
    display: inline-block;
    margin-left: 2%;
    padding: 8px;
}
.no-padding {
    padding: 0;
}
.bookmaker-profile p{
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    text-align: left;
    margin-top: 12px;
}
.bookmaker-profile img {
    margin-right: 8px;
    margin-bottom: 3px;
}
.bookmaker-profile h6{
    font-size: 12px;
    font-weight: normal;
    color: #fff;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0;
    text-transform: uppercase;
}
.bookmaker-profile h4{
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-top: 5px;
    margin-bottom: 0;
    padding: 0;
}
.bookmaker-profile .rating-box {
    position:relative;
    vertical-align: middle;
    font-size: 1.3em;
    font-family: FontAwesome;
    display:inline-block;
    color: #fff;
    display: inline-block;
    position: relative;
}
.bookmaker-profile .rating-box:before{
    content: "\f006  \f006  \f006  \f006  \f006";
}
.bookmaker-profile .rating-box .rating {
    position: absolute;
    left:0;
    top:0;
    white-space:nowrap;
    overflow:hidden;
    color: #fff;
}
.bookmaker-profile span.rating-digit {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin-left: 10px;
    vertical-align: middle;
}
.bookmaker-profile span.rating-digit small{
    font-size: 14px;
    font-weight: normal;
    margin-top: -3px;
    display: inline-block;
    vertical-align: middle;
    letter-spacing: 5px;
}
.bookmaker-profile ul {
    margin-top: 40px;
}
.bookmaker-profile ul li {
    list-style-type: none;
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}
.bookmaker-profile ul li span{
    letter-spacing: 3px;
}
.bookmaker-profile ul li span>span{
    color: #8CA9C0;
}
.page-tabs .page-tabs-head {
    background-color: var(--cal-main);
    box-shadow: 0px 2px 4px #456EA41A;
}
.page-tabs .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {border: none;}
.page-tabs ul.nav.nav-tabs {border: none; background: var(--cal-main);}
.page-tabs .nav>li>a {padding: 10px 0;border: none;}
.page-tabs .nav-tabs>li>a:hover {background: transparent;box-shadow: none;border: none;/* padding: 0; */}
.page-tabs .nav>li>a img {width: 20px;}
.page-tabs .nav-tabs>li {margin: 0 15px;padding: 0;}
.page-tabs .nav-tabs>li:first-child {margin: 0;padding: 0;}
.page-tabs .nav-tabs>li.active {border-bottom: 2px solid $primary-color;/* padding-bottom: 7px; */}

.page-tabs .nav>li>a {
    padding: 10px 0;
    border: none;
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    color: var(--font-color2);
}
.page-tabs .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: $primary-color;
    background-color: var(--cal-main);
}
.page-tabs h2{
    margin-top: 45px;
    margin-bottom: 25px;
    color: var(--font-color);
}
.page-tabs h4{
    margin-top: 25px;
    font-size: 18px;
    color: var(--font-color2);
}
.page-tabs p{
    margin-top: 25px;
    color: var(--font-color2);
}
#home ul{
    padding-left: 16px;
    margin-top: 20px;
}
#home ul li{
    margin-top: 10px;
    color: var(--font-color2);
    font-size: 15px;
}
.page-tabs .tab-content a {
    color: $primary-color;
    text-decoration: underline;
    font-weight: bold;
}
.no-margin-top {
    margin-top: 0 !important;
    top: 0;
}
ul.green-list {
    padding-left: 18px;
    margin-top: 0 !important;
}
ul.green-list li {
    font-size: 15px;
    color: $primary-color !important;
    list-style-type: none;
    font-weight: 400 !important;
    margin-left: 0;
    margin-top: 10px;
}
ul.green-list li:before {
    font-family: 'FontAwesome';
    content: '\f00c';
    margin:0 10px 0 -15px;
    font-size: 15px;
    color: $primary-color;
}
ul.red-list {
    padding-left: 18px;
    margin-top: 0 !important;
}
ul.red-list li {
    font-size: 15px;
    color: #DC223B !important;
    list-style-type: none;
    font-weight: 400 !important;
    margin-left: 0;
    margin-top: 10px;
}
ul.red-list li:before {
    font-family: 'FontAwesome';
    content: '\f00d';
    margin:0 10px 0 -15px;
    font-size: 15px;
    color: #DC223B;
}
ul.simple-list{
    padding-left: 18px;
    margin-top: 20px;
}
ul.simple-list li{
    margin-top: 10px;
    font-weight: 16px;
    color: var(--heading-col);
    font-weight: 500;
}
.more-detail-list {
    padding-top: 65px;
}
.more-details h6 {
    font-size: 12px;
    font-weight: normal;
    color: var(--font-color2);
    margin-top: 20px;
}
.more-details h4, .more-details h4 a {
    font-size: 18px;
    font-weight: bold;
    color: var(--font-color2);
    margin-top: 5px;
}
.more-details h4 a {
    text-decoration: underline;
}
.more-details img {
    margin-right: 10px;
    margin-top: -5px;
    display: inline-block;
}
.content-bg-white {
    background-color: var(--cal-main);
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
}
.content-bg-grey {
    background-color: var(--bg-color);
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
}
.content-bg-white h3, .content-bg-grey h3{
    font-size: 18px;
    color: var(--heading-col);
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
}
.content-bg-white p, .content-bg-grey p{
    font-size: 15px;
    color: var(--font-color2);
    margin-top: 20px;
}
.content-bg-white img, .content-bg-grey img{
    margin-top: 20px;
}
.accordion h4, .page-tabs .tab-content .accordion h4 a {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: var(--font-color2);
    text-decoration: none !important;
}
.accordion {
    margin-top: 30px;
}
.accordion h4, .page-tabs .tab-content .accordion h4 a[aria-expanded = "true"] {
    font-weight: bold;
    color: var(--heading-col);
}
.accordion .panel-default {
    box-shadow: 0px 2px 4px #456EA41A;
    border-radius: 5px;
    background-color: var(--cal-main);
    padding: 15px 15px;
    margin-top: 10px;
}
.accordion .panel-default>.panel-heading {
    background-color: var(--cal-main);
}
.accordion .panel-default>.panel-heading i {
    color: var(--font-color2);
    font-size: 18px;
    margin-right: 5px;
    vertical-align: middle;
}
.accordion h4 span.title {
    width: 80%;
    display: inline-block;
}
.accordion h4 span.pull-right {
    color: var(--font-color2);
    font-size: 14px;
    text-decoration: underline;
    font-weight: lighter;
}
.accordion .panel-default p {
    color: var(--font-color2);
    font-size: 15px;
}
.accordion .panel-default ul, .accordion .onexbet ul {
    margin-left: 0;
    padding-left: 25px;
}
.accordion .panel-default ul li, .accordion .onexbet ul li {
    color: var(--font-color2);
    font-size: 15px;
    margin-top: 5px;
}
.accordion .onexbet ul li {
    color: #fff;
}
.accordion .panel-default p:first-child{
    margin-top: 0;
}
.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border: 0 !important;
    color: var(--font-color2);
}
.accordion .onexbet>.panel-heading {
    border-radius: 5px;
}
.accordion .onexbet {
    box-shadow: 0px 2px 4px #456EA41A;
    border-radius: 5px;
    background-color: var(--font-color2);
    padding: 0 !important;
    margin-top: 10px;
}
.accordion .onexbet>.panel-heading i {
    color: #fff;
}
.onexbet h4, .onexbet h4 span {
    color: #fff !important;
}
.accordion .onexbet p {
    color: #fff;
}
.onexbet h4, .onexbet h4 a {
    color: #fff !important;
}
.onexbet h4{
    padding: 15px 15px !important;
}
.onexbet h4, .onexbet h4 a[aria-expanded = "true"] {
    color: #fff;
}
.onexbet.panel-default>.panel-heading+.panel-collapse>.panel-body {
    padding: 15px 35px !important;
}
.onexbet .panel-footer {
    background-image: url('/img/frontend/1xbet-panel-footer_nobg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    width: 100%;
    min-height: 112px;
    display : flex;
    align-items : center;
}
.onexbet .panel-footer h5{
    color: #fff;
    font-size: 18px;
    margin-left: 20px;
}
.onexbet .panel-footer img {
    float: left;
}
.onexbet .panel-footer a{
    color: #fff;
    font-size: 18px;
    background-color: #34BAFF;
    padding: 6px 15px;
    text-decoration: none;
    border-radius: 5px;
    margin-left: auto;
    text-align: center;
}
.page-tabs .maintabs {
    margin-top: 30px;
}
.page-tabs .content-section {
    padding: 0;
}
.page-tabs .odds-comparison h3 {
    font-size: 26px;
    margin-bottom: 0px;
    margin-top: 30px;
}
.page-tabs table tr td {
    color: var(--heading-col);
    font-weight: normal;
    font-size: 15px;
}
.page-tabs table tr td.bold {
    font-weight: bold;
}
.page-tabs table tr td img{
    margin-top: -3px;
}
.page-tabs .maintabs ul.nav.nav-tabs {
    border-bottom: 2px solid #EAEEF3;
    padding-left: 30px;
}
.payment_Methods_tabs .tab-content>div {
    padding: 0 30px 30px 30px;
}
.payment-methods .col-md-2 {
    padding-right: 10px;
    padding-left: 10px;
}
.payment-methods .payment-method {
    border: 2px solid #CAD2E2;
    border-radius: 5px;
    width: 100%;
    min-height: 120px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 20px;
}
.payment-methods .payment-method h6 {
    color: var(--heading-col);
    font-size: 15px;
    font-weight: bold;
    display: block;
    margin: auto;
    margin-top: 0;
}
.payment-methods .payment-method div.sprite {
    width: 60px;
    display: block;
    margin: auto;
}
.other-bookmakers-select {
    background-color: var(--cal-main);
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 2px 4px #456EA41A;
}
.other-bookmakers-select a{
    background-color: $primary-color;
    color: #fff !important;
    text-decoration: none !important;
    padding: 5px 15px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 50px;
    margin-right: 5px;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
}
.other-bookmakers-select a i {
    margin-right: 10px;
}
.other-bookmakers-select a.no-bg{
    background: transparent;
    color: $primary-color !important;
    border: 2px solid $primary-color;
}
.other-bookmakers-select .dropdown {
    display: inline-block;
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
}
.other-bookmakers-select .dropdown a {
    background-color: transparent !important;
}
.other-bookmakers-select .dropdown button {
    background: transparent;
    color: $primary-color;
    border:0;
    font-size: 15px;
    font-weight: bold;
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open>.dropdown-toggle.btn-primary.focus, .open>.dropdown-toggle.btn-primary:focus, .open>.dropdown-toggle.btn-primary:hover {
    color: $primary-color;
    background-color: #fff;
    border-color: #fff;
}
.other-bookmakers-select  .dropdown-menu>li>a {
    background: #fff;
    color: var(--font-color2) !important;
}
.bookmakers-register-list {
    text-align: center;
}
.bookmakers-register-list .bookmakers-register {
    width: 176px !important;
    height: auto;
    margin-top: 20px;
    display: inline-block;
    margin-left: 1px;
    position: relative;
    vertical-align: text-top;
}
.bookmakers-register-list .bookmakers-register .bookmakers-register-inside {
    box-shadow: 0px 2px 4px #456EA41A;
    display: block;
    padding: 1px;
}
.bookmakers-register-list .bookmakers-register:first-child {
    margin-left: 0;
}
.bookmakers-register-list .bookmakers-register a.remove {
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    text-decoration: none;
    color: var(--font-color2);
    font-size: 14px;
    font-weight: normal;
}
.bookmakers-register-list .bookmakers-register a.remove i{
    margin-right: 5px;
}
.bookmakers-register-list .bookmakers-register .bookie-of-month {
    background-color: #dc223b;
    text-align: center;
    padding: 2px;
    border-radius: 2px;
    width: 100%;
}
.bookmakers-register-list .bookmakers-register .bookie-of-month.absolute{
    position: absolute;
    width: 99%;
}
.bookmakers-register-list .bookmakers-register .bookie-of-month p {
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
}
.bookmakers-register-list .bookmakers-register img {
    width: 100%;
    height: auto;
}
.bookmakers-register-list .bookmakers-register ul {
    margin: 0;
    padding: 0;
}
.bookmakers-register-list .bookmakers-register ul.active {
    box-shadow: inset 0 0 0 2px var(--font-color2);
    padding: 2px;
    z-index: 10000;
}
.bookmakers-register-list .bookmakers-register ul li {
    list-style-type: none;
    text-align: center;
    margin:0;
    padding: 10px;
    color: var(--font-color2);
    font-size: 18px;
    font-weight: bold;
    background-color: var(--cal-main);
}
.bookmakers-register-list .bookmakers-register ul li small {
    font-size: 14px;
    font-weight: normal;
    color: var(--font-color2);
    display: block;
}
.bookmakers-register-list .bookmakers-register ul li small.terms {
    font-size: 10px;
    font-weight: normal;
    color: var(--font-color2);
    display: block;
}
.bookmakers-register-list .bookmakers-register ul li i {
    display: block;
}
.bookmakers-register-list .bookmakers-register ul li i.fa-check {
    color: $primary-color;
    font-weight: normal;
    margin-top: 5px;
}
.bookmakers-register-list .bookmakers-register ul li i.fa-close {
    color: #dc223b;
    font-weight: normal;
    margin-top: 5px;
}
.bookmakers-register-list .bookmakers-register ul li span.lg {
    font-size: 24px;
    font-weight: bold;
    color: var(--heading-col);
}
.bookmakers-register-list .bookmakers-register ul li a.register-now {
    text-decoration: none;
    background-color: $primary-color;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 25px;
    color: #fff !important;
    border-radius: 5px;
    width: 100%;
}
.bookmakers-register-list .bookmakers-register ul li:nth-of-type(odd) {
    background-color: var(--navbar);
}
.bookmakers-register-list .bookmakers-register .rating-box {
    position:relative;
    vertical-align: middle;
    font-size: 1em;
    font-family: FontAwesome;
    display:inline-block;
    color: var(--font-color2) !important;
    display: inline-block;
    position: relative;
    margin-top: 10px;
}
.bookmakers-register-list .bookmakers-register  .rating-box:before{
    content: "\f006  \f006  \f006  \f006  \f006";
}
.bookmakers-register-list .bookmakers-register  .rating-box .rating {
    position: absolute;
    left:0;
    top:0;
    white-space:nowrap;
    overflow:hidden;
    color: var(--font-color2) !important;
}
.bookmakers-register-list .bookmakers-register  span.rating-digit {
    font-size: 18px;
    font-weight: bold;
    color: var(--font-color2);
    margin-left: 10px;
    vertical-align: middle;
    display: block;
}
.bookmakers-register-list .bookmakers-register  span.rating-digit small{
    font-size: 18px;
    font-weight: bold;
    margin-top: -3px;
    display: inline-block;
    vertical-align: middle;
    letter-spacing: 5px;
    color: #CAD2E2;
}
.user-reviews .content-section {
    padding: 25px;
}
.user-reviews h4 {
    margin-bottom: 15px;
    display: block;
    margin-top: 0 !important;
}
.user-reviews form input, .user-reviews form textarea {
    border: 2px solid #CAD2E2;
    border-radius: 4px;
    height: 40px;
    font-style: italic;
    color: var(--font-color2);
}
.user-reviews .form-group label {
    font-size: 13px;
    color: var(--font-color2);
    position: relative;
    top: 14px;
    left: 10px;
    background: #fff;
    padding: 0 3px;
    text-align: left;
}
.user-reviews a.login-btn {
    border: 2px solid $primary-color;
    font-size: 14px;
    font-weight: bold;
    color: var(--heading-col);
    display: block;
    margin-top: 40px;
    padding: 9px 15px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none !important;
    height: 40px;
}
.no-padding-left {
    padding-left: 0;
}
.user-reviews form textarea {
    width: 100%;
    height: 120px;
}
.user-reviews input.submit {
    background-color: $primary-color;
    border: 0;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: #FFFFFF;
    padding: 15px 55px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none !important;
    float: right;
    height: auto;
    box-shadow: 0px 2px 4px #456EA41A;
    margin-top: 10px;
}
.review h5 {
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    color: var(--heading-col);
    display: inline-block;
}
.review h5 img {
    margin-right: 10px;
    margin-top: -5px;
}
.review h6 {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    color: var(--font-color);
    float: right;
    display: inline-block;
}
.review p {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    color: var(--font-color2);
}
.review .review-footer {
    margin-top: 30px;
}
.review .review-footer a.like {
    text-decoration: none;
    font-size: 18px;
    color: $primary-color;
    display: inline-block;
}
.review .review-footer a.like i{
    font-size: 28px;
    color: $primary-color;
    margin-right: 4px;
}
.review .review-footer a.dislike {
    text-decoration: none;
    font-size: 18px;
    color: #DC223B;
    display: inline-block;
    margin-left: 20px;
}
.review .review-footer a.dislike i{
    font-size: 28px;
    color: #DC223B;
    margin-right: 4px;
    display: inline-block;
}
a.report {
    text-decoration: none !important;
    font-size: 14px;
    font-weight: lighter;
    font-style: normal;
    color: var(--font-color2) !important;
}
a.report img {
    margin-right: 5px;
    margin-top: -5px;
}
.breadcrumb {
    padding: 0;
    margin-bottom: 20px;
    list-style: none;
    background-color: transparent;
    border-radius: 4px;
}
.breadcrumb.light {
    margin-top: 20px !important;
}
.breadcrumb li a, .breadcrumb li {
    font-size: 15px;
    font-weight: bold;
    color: var(--font-color2);
}
.breadcrumb.light li a, .breadcrumb.light li {
    font-size: 15px;
    font-weight: bold;
    color: #fff;
}
.breadcrumb li a.active {
    font-size: 15px;
    font-weight: lighter;
    color: var(--font-color2);
}
.breadcrumb.light li a.active {
    font-size: 15px;
    font-weight: lighter;
    color: #fff;
}
.breadcrumb.green>li+li:before {
    padding: 0 5px;
    font-family: FontAwesome;
    font-size: 10px;
    color: $primary-color;
    content: "\f054";
}
.breadcrumb.light>li+li:before {
    padding: 0 8px;
    font-family: FontAwesome;
    font-size: 10px;
    color: #fff;
    content: "\f054";
}
.similar-bookmaker {
    background-color: var(--cal-main);
    margin-top: 7px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px #456EA41A;
}
.similar-bookmaker img {
    display: inline-block;
    width: 80px;
}
.similar-bookmaker h4 {
    font-size: 14px;
    font-weight: bold;
    color: var(--heading-col);
    display: block;
    vertical-align: top;
    margin-top: 10px !important;
    margin-left: 10px !important;
}
.similar-bookmaker a.claim-btn {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: $primary-color;
    padding: 3px 5px;
    border-radius: 5px;
    width: 100px;
    height: 26px;
    display: block;
    text-align: center;
    text-decoration: none;
    float: left;
    margin-left: 10px;
}
.page-tabs .sidebar {
    padding-top: 30px !important;
}
.table-content {
    background-color: var(--cal-main);
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 2px 4px #456EA41A;
}
.table-content ol {
    margin-left: 0;
    padding-left: 15px;
}
.table-content ol li, .table-content ol li a {
    font-size: 14px !important;
    font-weight: normal !important;
    color: var(--font-color2) !important;
    margin-bottom: 10px;
    text-decoration: none !important;
}
.table-content ol li.active a, .table-content ol li.active{
    color: $primary-color !important;
    font-weight: bold !important;
}
.table-content ol li:last-child{
    margin-bottom: 0;
}
.onexbet_promo {
    width: 100%;
    position: fixed;
    z-index: 80;
    margin-top: 75px;
    display: none;
    top: 0;
}
.onexbet_promo .close-btn {
    color: #fff;
    float: right;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 17px;
}
.onexbet_promo img{
    display: block;
    vertical-align: middle;
    margin: 10px 0;
    width: 112px;
    display: inline-block;
}
.onexbet_promo h3{
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 10px;
    margin-left: 20px;
    display: inline-block;
    text-align: left;
}
.onexbet_promo a.register{
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    padding: 8px 40px;
    margin: 0;
    background-color: #1B90DF;
    display: inline-block;
    border-radius: 5px;
    width: 300px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}
.main-newsletter .content-section {
    padding: 23px;
}
.main-newsletter h5 {
    font-size: 14px;
    font-weight: normal;
    color: var(--font-color2);
}
.main-newsletter p {
    font-size: 14px;
    font-weight: normal;
    color: var(--font-color2);
}
.main-newsletter .checkbox label span {
    margin-top: 10px;
    font-size: 14px;
    font-style: italic;
    color: var(--font-color2);
}
.main-newsletter .checkbox label span a{
    color: $primary-color;
    text-decoration: underline;
}
.main-newsletter .btn5 {
    margin-top: 30px;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
}
.promotion-box {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    background: url('/img/frontend/offer_bg_1xbet.jpg');
    background-position: bottom right;
    border-radius: 5px;
    box-shadow: 0px 2px 4px #456EA41A;
}
.promotion-box h5{
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-top: 0;
}
.promotion-box p{
    font-size: 10px;
    font-weight: 500;
    color: #fff;
}
.promotion-box a.bet-now{
    font-size: 18px;
    font-weight: 500;
    color: #FFE418;
    background-color: $primary-color;
    padding: 10px 30px;
    display: inline-block;
    border-radius: 5px;
    text-align: center;
    margin-top: 12%;
}
.mt-40 {
    margin-top: 40px;
}
.pl-0 {
    padding-left: 0;
}
a.claim-green-btn {
    color: #fff !important;
    font-size: 18px;
    background-color: var(--bg-green) !important;
    padding: 6px 15px;
    text-decoration: none !important;
    border-radius: 5px;
    margin-left: auto;
    text-align: center;
    margin-top: 20px;
}
.mt-0 {
    margin-top: 0;
}
.news-items {
    margin-top: 20px;
}
.news-items .news-item:first-child{
    margin-top: -2px;
}
.news-items .news-item {
    margin-top: 5px;
    display: block;
    background-color: var(--cal-main);
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0px 2px 4px #456EA41A;
}
.news-items .news-item h3{
    margin-top: 0;
    margin-bottom: 5px;
}
.news-items .news-item p.news-date{
    margin-bottom: 5px;
    color: var(--font-color);
}
.news-items .news-item a.read-more {
    color: $primary-color;
    display: block;
    margin-top: 5px;
    font-weight: bold;
}
.news-items {
    margin-top: 20px;
}
.links-items {
    margin-top: 20px;
}
.links-items .links-item:first-child{
    margin-top: -2px;
}
.links-items .links-item {
    margin-top: 5px;
    display: block;
    background-color: var(--cal-main);
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0px 2px 4px #456EA41A;
}
.links-items .links-item h3{
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    color: $primary-color;
    text-decoration: underline;
}
.links-items .links-item p.link{
    margin-bottom: 5px;
    color: var(--font-color);
}
.news-items .news-item a.news {
    color: var(--font-color);
}
.links-items .links-item a.btn-green-outline {
    margin-top: 6px;
}
.mobile-lang {
    float: right;
    margin-top: 20px;
    margin-right: 13px;
}
/*Prediction page end*/
@media (min-width: 1200px) {
    .container {
        width: 1230px;
    }
}
@media (min-width: 992px) {
    .mod-grid > .col-md-9 {
        width: 73%;
    }
    .mod-grid > .col-md-3 {
        width: 27%;
    }
}
@media (max-width: 992px) {
    .bookmakers-menu a.menu-item{
        margin-top: 20px;
    }
    .bookmakers-list-item .cta {
        float: right;
        margin-top: 10px;
    }
    .prediction_head_footer div {
        text-align: center;
    }
    .prediction_head_footer  {
        margin-top: 20px;
    }
    .bookmakers-list-item {
        min-height: 150px;
        margin: 10px;
    }
    .navbar-custom .navbar-nav:first-child{
        display: none;
    }
    .mobile-nav-icon {
        display: block;
    }
}
@media (max-width: 690px) {
    .navbar-brand>img {
        margin-left: 10px;
    }
    .pl-0 {
        padding-left: 20px;
    }
    .promotion-box {
        padding: 15px;
        text-align: center;
    }
    .promotion-box img {
        width: 180px;
        margin: 10px auto;
    }
    .promotion-box a.bet-now{
        margin-top: 20px;
    }
    .srch1 {
        display: block;
    }
    #navbar {
        display: none;
    }
    .mobile-nav-icon {
        display: block;
    }
    .other-bookmakers-select {
        min-height: 200px;
    }
    .onexbet_promo img{
        margin: 25px auto;
        float: none !important;
    }
    .onexbet_promo h3 {
        text-align: center;
        margin: 0;
        display: block;
        margin-bottom: 10px;
    }
    .onexbet_promo a.register {
        float: none;
        margin-bottom: 10px;
        width: 100%;
    }
    .onexbet_promo {
        margin-top: 60px;
        text-align: center;
    }
    .no-padding {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .page-tabs .nav li {
        float: none;
    }
    .page-tabs .nav-tabs>li {
        margin: 0;
        padding: 0;
    }
    .panel .panel-heading span {
        /*direction: splay: none !important;*/
    }
    .onexbet .panel-footer {
        display: block;
    }
    .onexbet .panel-footer * {
        display: block;
    }
    .onexbet .panel-footer h5 {
        margin-top: 80px;
    }
    .bookmakers-list-item {
        display: flex;
        flex-wrap: wrap;
        min-height: 180px;
        margin: 10px;
    }
    .bookmakers-list-item .cta {
        float: left;
        margin-left: 10px;
        margin-top: 0;
        margin-bottom: 10px;
    }
    .onexbet .panel-footer img {
        margin-left: 30%;
    }
    .bookmakers-list-item.sponsor::before {
        content: '';
    }
    .bookmakers-list-item .des {
        display:  block;
        padding-left: 0;
        padding: 8px;
        margin: 0;
        width: 100%;
        float: left;
    }
    .bookmakers-list-item p {
        margin-left: 0;
    }
    .bookmakers-list-item.sponsor img {
        padding-left: 2px;
    }
    .prediction_head_footer div {
        text-align: center;
    }
    .prediction_head_footer  {
        margin-top: 20px;
    }
    .prediction-head-col {
        padding: 0;
    }
    .bookmakers-register-list .bookmakers-register ul.active {
        box-shadow: none;
        border: 0;
        padding: 0;
        z-index: 10000;
    }
    .circle-container {
        padding: 0;
    }
    .circle-container-main {
        padding: 0;
    }
    .bookmaker-profile-container {
        margin-top: 50px;
    }
    .bookmaker-profile {
        margin-top: 50px;
    }
    .content-section {
        padding: 20px 20px;
    }
    .timebx {
        width: 30%;
    }
}
@media screen and (max-width: 430px){
    .main-mob-nav .srch {width: 428px;}
}

@media screen and (max-width: 414px){
    .postinr {width: 78%; padding: 9px 0;}
    .main-mob-nav ul li:nth-child(3):after {left: 380px;}
    .main-mob-nav ul li:nth-child(2) {width: 58%;}
    .cal-nav ul li {margin: 0 15px 0 0;}
    .btn5 {text-align: left; margin: 0}
    .main-mob-nav .srch  {width: 412px; left: -40px; margin: 0}
    .srch input {position: relative;}
    .timebx a {float: left;}
    .more-pre h2 {margin: 37px 0px 20px 0;text-align: left;}
    .d-li {width: 64%;}
}

@media screen and (max-width: 411px){
    .main-mob-nav .srch {left: -38px; width: 409px;}
    .more-pre h2 {text-align: left; width: 100%;}

}

@media screen and (max-width: 375px){
    .postinr {width: 76%;padding: 9px 0;}
    .cal-nav ul li {margin: 0 10px 0 0;}
    .more-pre h2 {text-align: left; width: 100%;}
    .d-li {width: 64%;}
}

@media screen and (max-width: 360px) {
    .main-mob-nav ul li:nth-child(4) {
        margin: 0 0 0 -10px;}
    .main-mob-nav ul li:nth-child(3):after {
        left: 344px;}
    .main-mob-nav ul li:nth-child(3):after {
        left: 338px;}
    .cal-nav ul li {margin: 0 14px 0 0;}
}

@media screen and (max-width: 320px){
    .main-mob-nav ul li:nth-child(3):after {left: 308px;}
    .postinr { width: 72%; padding: 0px 8px;}
    .cal-nav ul li { margin: 0 9px 0 0;}
    .timebx a {}
    .more-pre h2 {text-align: left; width: 100%;}
    .srch i.fa.fa-close {left: 34px;}

}
.content-section form input, .content-section form textarea, .content-section form select {
    border: 2px solid #CAD2E2;
    border-radius: 4px;
    height: 40px;
    font-style: italic;
    color: var(--font-color2);
    background-color: var(--cal-main);
}
.content-section .form-group label {
    font-size: 13px;
    color: var(--font-color2);
    position: relative;
    top: 14px;
    left: 10px;
    background: #fff;
    padding: 0 3px;
    text-align: left;
    background-color: var(--cal-main);
}
.content-section form textarea {
    width: 100%;
    height: 120px;
}
.content-section input.submit {
    background-color: $primary-color;
    border: 0;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: #FFFFFF;
    padding: 15px 55px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none !important;
    float: right;
    height: auto;
    box-shadow: 0px 2px 4px #456EA41A;
}
.video_margin {
    margin-bottom: 20px;
}
.mTop10 {
    margin-top: 10px;
}
.youtube {
    background-color: #000;
    margin-bottom: 30px;
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    cursor: pointer;
}
.youtube img {
    width: 100%;
    top: -16.84%;
    left: 0;
    opacity: 0.7;
}
.youtube .play-button {
    width: 90px;
    height: 60px;
    background-color: #333;
    box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
}
.youtube .play-button:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26.0px;
    border-color: transparent transparent transparent #fff;
}
.youtube img,
.youtube .play-button {
    cursor: pointer;
}
.youtube img,
.youtube iframe,
.youtube .play-button,
.youtube .play-button:before {
    position: absolute;
}
.youtube .play-button,
.youtube .play-button:before {
    top: 50%;
    left: 50%;
    transform: translate3d( -50%, -50%, 0 );
}
.youtube iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.sprite {
    display: inline-block;
    background: url("/img/frontend/payments.png") no-repeat;
}
.bankwire {
    width: 60px;
    height: 25px;
    background-position: 0 0px;
}
.visa {
    width: 60px;
    height: 25px;
    background-position: 0 -25px;
}
.mastercard {
    width: 60px;
    height: 38px;
    background-position: 0 -51px;
}
.entropay {
    width: 60px;
    height: 38px;
    background-position: 0 -90px;
}
.ecopayz {
    width: 60px;
    height: 25px;
    background-position: 0 -130px;
}
.western-union {
    width: 60px;
    height: 25px;
    background-position: 0 -160px;
}
.clickandbuy {
    width: 60px;
    height: 30px;
    background-position: 0 -191px;
}
.revolut {
    width: 60px;
    height: 22px;
    background-position: 0 -222px;
}
.paysafe {
    width: 60px;
    height: 25px;
    background-position: 0 -244px;
}
.paypal {
    width: 60px;
    height: 20px;
    background-position: 0 -266px;
}
.skrill {
    width: 60px;
    height: 24px;
    background-position: 0 -284px;
}
.neteller {
    width: 60px;
    height: 24px;
    background-position: 0 -308px;
}
.maestro {
    width: 60px;
    height: 36px;
    background-position: 0 -332px;
}
.visa-electron {
    width: 60px;
    height: 38px;
    background-position: 0 -378px;
}
.giropay {
    width: 60px;
    height: 25px;
    background-position: 0 -416px;
}
.qiwi {
    width: 60px;
    height: 25px;
    background-position: 0 -441px;
}
.webmoney {
    width: 60px;
    height: 26px;
    background-position: 0 -470px;
}
.moneta {
    width: 60px;
    height: 25px;
    background-position: 0 -510px;
}
.euteller {
    width: 60px;
    height: 23px;
    background-position: 0 -533px;
}
.epay {
    width: 60px;
    height: 24px;
    background-position: 0 -551px;
}
.przelewy24 {
    width: 60px;
    height: 24px;
    background-position: 0 -574px;
}
.boleto {
    width: 60px;
    height: 28px;
    background-position: 0 -600px;
}
.itau {
    width: 60px;
    height: 59px;
    background-position: 0 -630px;
}
.santander {
    width: 60px;
    height: 20px;
    background-position: 0 -688px;
}
.sofort-uberweisung {
    width: 60px;
    height: 25px;
    background-position: 0 -702px;
}
.bpay {
    width: 60px;
    height: 25px;
    background-position: 0 -730px;
}
.multibanco {
    width: 60px;
    height: 25px;
    background-position: 0 -768px;
}
.crypto-currencies {
    width: 60px;
    height: 30px;
    background-position: 0 -804px;
}
.eps {
    width: 60px;
    height: 25px;
    background-position: 0 -838px;
}
.trustly {
    width: 60px;
    height: 25px;
    background-position: 0 -866px;
}
.yandex {
    width: 60px;
    height: 25px;
    background-position: 0 -894px;
}
.ukash {
    width: 60px;
    height: 25px;
    background-position: 0 -919px;
}
.idebit {
    width: 60px;
    height: 31px;
    background-position: 0 -948px;
}
.zimpler {
    width: 60px;
    height: 23px;
    background-position: 0 -980px;
}
.easypay {
    width: 60px;
    height: 25px;
    background-position: 0 -1005px;
}

/* theme swticher */
.theme-switch-wrapper {
    display: flex;
    align-items: center;

    em {
        margin-left: 10px;
        font-size: 1rem;
    }
}
.theme-switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px;
}

.theme-switch input {
    display:none;
}
.slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}
.slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 26px;
}

input:checked + .slider {
    background-color: #66bb6a;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
#poll {
    padding: 0 5px 20px 5px;
}
#poll p {
    color: var(--font-color2);
}
.mega-dropdown {
    position: static !important;
}
#sidenav .dropdown-menu {
    position: relative;
}
@media only screen and (min-width: 800px) {
    .mega-dropdown:hover .mega-dropdown-menu{
        display: block;
        margin-top: -15px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
}
#sidenav .mega-dropdown-menu{
    margin-top: 10px;
    margin-bottom: 20px;
    box-shadow: none;
}
.mega-dropdown-menu {
    padding: 20px 0px;
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
}
.mega-dropdown-menu  li > ul {
    padding: 0;
    margin: 0;
}
.mega-dropdown-menu  ul > li {
    list-style: none;
}
.mega-dropdown-menu  ul > li > a {
    display: block;
    color: var(--font-color);
    padding: 8px 5px;
}
.mega-dropdown-menu > li ul > li > a:hover,
.mega-dropdown-menu > li ul > li > a:focus {
    text-decoration: none;
}
.mega-dropdown-menu .dropdown-header {
    font-size: 18px;
    color: $primary-color;
    padding: 5px 60px 5px 5px;
    line-height: 30px;
    margin-bottom: 10px;
    font-weight: bold;
}
.list-cols {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin: 0;
    padding: 0;
}
#sidenav .list-cols {
    flex-direction: column;
    gap: 20px;
}
.list-cols__separator {
    border: 1px solid #ddd;
    height: 200px;
}
#sidenav .list-cols__separator {
    border: 0;
    height: 0;
}

#DataTables_Table_0_filter input[type=search] {
    margin: 0px 10px 0 10px;
    border: 2px solid #cad2e2;
    border-radius: 4px;
    height: 40px;
    font-style: italic;
    color: var(--font-color2);
    background-color: var(--cal-main);
}
